@import "./_variables.scss";

.general {
  width: calc(100% - 4em);
  margin: 0 2em;

  td {
    padding: 1em 0.5em;
  }

  th {
    padding: 0.5em;
    text-align: left;
    color: black;
    vertical-align: bottom;

    &:last-child:not(.country) {
      span:nth-child(2),
      img[alt="placeholder"] {
        display: none;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #e2e2e2;
      transition: 0.4s ease-out;
      transition-property: background-color;

      &:hover {
        background-color: rgba(black, 0.04);
        transition: 0.2s ease-out;
        transition-property: background-color;

        .popoverScore {
          opacity: 1;
          transform: translateY(1em);
          transition: 0.2s ease-out;
          transition-property: opacity transform;
        }
      }
    }
  }

  thead {
    border-bottom: 2px solid #b2b2b2;
  }

  @media screen and (max-width: $tablet-breakpoint) {
    width: 100%;
    margin: 0;
  }
}

.wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 1em auto;
  position: relative;

  @media screen and (max-width: $mobile-breakpoint) {
    padding: 0;
  }
}

.subHeader {
  flex-direction: row;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 1.12em;
  margin-bottom: 1.55em;
  padding: 0 2em;

  > div {
    display: flex;
    flex-direction: row;

    > *:not(:last-child) {
      margin: 0.5em;
    }
  }

  @media screen and (max-width: $tablet-breakpoint) {
    flex-direction: column;
    padding: 0 1em;
    line-height: 1.2;
    align-items: flex-start;
  }

  @media screen and (max-width: $mobile-breakpoint) {
    padding: 1em;
  }
}

.depthCell {
  display: inline-block;
  background: #b2b2b2;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  color: white;
  font-size: 0.9em;
  padding: 0.325em 0.3em 0.25em;
  height: 1em;
  position: relative;
}

.breadthCell {
  display: inline-block;
  background: $DHL-yellow;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  color: white;
  font-size: 0.9em;
  padding: 0.325em 0.3em 0.25em;
  height: 1em;
  position: relative;
}

.popoverScore {
  opacity: 0;
  border-radius: $border-radius;
  box-shadow: 0px 1px 2px #00000029;
  padding: 0.5em;
  background: white;
  color: black;
  display: inline-block;
  transform: translateY(1.2em);
  transition: 0.3s 0.3s ease-out;
  transition-property: opacity transform;
  position: absolute;
  bottom: -0.5em;
}

.depthCell .popoverScore {
  right: 0.5em;
}

.breadthCell .popoverScore {
  left: 0.5em;
}

.changeNegative {
  &:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 0.4em solid transparent;
    border-right: 0.4em solid transparent;
    border-top: 0.45em solid #f00;
    display: inline-block;
    margin: 0.05em 0.3em 0.05em 0;
  }
}

.changePositive {
  &:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 0.4em solid transparent;
    border-right: 0.4em solid transparent;
    border-bottom: 0.45em solid #1e9e5e;
    display: inline-block;
    margin: 0.05em 0.3em 0.05em 0;
  }
}

.pdfDownloadCell {
  img {
    width: 1em;
    opacity: 0.3;
  }

  a img {
    opacity: 1;
  }
}

td.rank {
  max-width: 10px;
  font-weight: 600;
  text-align: center;

  @media screen and (max-width: $mobile-breakpoint) {
    max-width: 10%;
    padding-left: 2em;
  }
}

td.change {
  max-width: 10px;

  @media screen and (max-width: $mobile-breakpoint) {
    max-width: 10%;
  }
}

td.country {
  max-width: 210px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: $tablet-breakpoint) {
    max-width: 140px;
  }

  @media screen and (max-width: $mobile-breakpoint) {
    width: 60%;
    overflow: hidden;
  }
}

td.depth_score {
  text-align: right;
  padding-right: 0;
  min-width: 200px;

  @media screen and (max-width: $tablet-breakpoint) {
    min-width: 170px;
  }
}

td.breadth_score {
  padding-left: 0;
  min-width: 200px;

  @media screen and (max-width: $tablet-breakpoint) {
    min-width: 180px;
  }
}

td:last-child:not(.country) {
  max-width: 20px;
  text-align: center;
}

th.change {
  padding-right: 1em;

  span {
    position: relative;
  }

  img {
    position: absolute;
    bottom: 5px;
  }

  @media screen and (max-width: $tablet-breakpoint) {
    transform: none;
  }
}

th.breadth_score,
th.depth_score,
th:last-child:not(.country),
td.breadth_score,
td.depth_score,
td:last-child:not(.country) {
  @media screen and (max-width: $mobile-breakpoint) {
    display: none;
  }
}

th.rank {
  min-width: 70px;

  @media screen and (max-width: $mobile-breakpoint) {
    padding-left: 2em;
  }

  @media screen and (max-width: $small-mobile-breakpoint) {
    min-width: 80px;
  }
}

th.depth_score {
  text-align: right;
}

.countryFlag {
  width: 1.5em;
  height: 1.1em;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 0.3em;
  vertical-align: sub;
  border: 1px solid #efefef;
  box-shadow: 0 0 0.1em 0 rgba(0, 0, 0, 0.1);
}

.emptyFlag {
  width: 1.5em;
  height: 1.1em;
  display: inline-block;
  margin-right: 0.3em;
  vertical-align: sub;
}
