@import "./_variables.scss";

.wrapper {
  display: flex;
  justify-content: center;
  margin: 2em 0;
  flex-wrap: wrap;
  position: relative;

  @media screen and (min-width: $tablet-breakpoint) {
    tspan {
      font-size: 14px;
    }
  } 
}

.label {
  font-weight: 900;
}

.bar {
  pointer-events: none;
}