$DHL-yellow: #FFCC00;
$DHL-red: #D40511;

$light-grey: #00000005;

$border-radius: 4px;

$tablet-breakpoint: 992px;
$mobile-breakpoint: 767px;
$small-mobile-breakpoint: 500px;

$max-width: 1000px;