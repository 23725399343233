/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable-next-line */
/* stylelint-disable-next-line */
/* stylelint-disable-next-line */
/* stylelint-disable */
/* stylelint-enable */
.clearfix::before,
.clearfix::after {
  content: ' ';
  display: table;
}
.clearfix::after {
  clear: both;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.hidden {
  display: none !important;
}
.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}
.fade.in {
  opacity: 1;
}
.collapse {
  display: none;
}
.collapse.in {
  display: block;
}
tr.collapse.in {
  display: table-row;
}
tbody.collapse.in {
  display: table-row-group;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease, visibility 0.35s ease;
}
.sr-only {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
@keyframes shake {
  0%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  16.7%,
  50%,
  83.3% {
    transform: translate3d(-10px, 0, 0);
  }
  33.3%,
  66.7% {
    transform: translate3d(10px, 0, 0);
  }
}
@keyframes slideUpIn {
  0% {
    transform-origin: 0% 0%;
    transform: scaleY(0.8);
  }
  100% {
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
}
@keyframes slideDownIn {
  0% {
    transform-origin: 100% 100%;
    transform: scaleY(0.8);
  }
  100% {
    transform-origin: 100% 100%;
    transform: scaleY(1);
  }
}
@keyframes slideLeftIn {
  0% {
    transform-origin: 0% 0%;
    transform: scaleX(0.8);
  }
  100% {
    transform-origin: 0% 0%;
    transform: scaleX(1);
  }
}
@keyframes slideRightIn {
  0% {
    transform-origin: 100% 100%;
    transform: scaleX(0.8);
  }
  100% {
    transform-origin: 100% 100%;
    transform: scaleX(1);
  }
}
@keyframes errorMessageSlideUpIn {
  0% {
    transform: translate3d(0, 2px, 0);
    visibility: visible;
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes errorMessageSlideDownIn {
  0% {
    transform: translate3d(0, -2px, 0);
    visibility: visible;
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes errorMessageSlideLeftIn {
  0% {
    transform: translate3d(-2px, 0, 0);
    visibility: visible;
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes errorMessageSlideRightIn {
  0% {
    transform: translate3d(2px, 0, 0);
    visibility: visible;
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes buttonSpin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes messageMoveIn {
  0% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0.8);
  }
  100% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
}
@keyframes messageMoveOut {
  0% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
    max-height: 150px;
  }
  100% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0.8);
    max-height: 0;
    overflow: hidden;
  }
}
@keyframes alertMoverIn {
  0% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0.8);
  }
  100% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
}
@keyframes alertMoveOut {
  0% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
    max-height: 60px;
  }
  100% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0.8);
    max-height: 0;
    overflow: hidden;
  }
}
@keyframes notificationMoverIn {
  0% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0.8);
  }
  100% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
}
@keyframes notificationMoveOut {
  0% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
    max-height: 100px;
  }
  100% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0.8);
    max-height: 0;
    overflow: hidden;
  }
}
@keyframes notificationMoveInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes notificationMoveInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes bounceIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes bounceOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.8);
  }
}
@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes slideOutLeft {
  from {
    opacity: 1;
    transform: none;
  }
  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes slideOutRight {
  from {
    opacity: 1;
    transform: none;
  }
  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes slideInTop {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes slideOutTop {
  from {
    opacity: 1;
    transform: none;
  }
  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}
@keyframes slideInBottom {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes slideOutBottom {
  from {
    opacity: 1;
    transform: none;
  }
  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}
@keyframes loaderSpin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes progress-active {
  0% {
    opacity: 0.1;
    width: 0;
  }
  20% {
    opacity: 0.5;
    width: 0;
  }
  100% {
    opacity: 0;
    width: 100%;
  }
}
@keyframes progress-active-vertical {
  0% {
    opacity: 0.1;
    height: 0;
  }
  20% {
    opacity: 0.5;
    height: 0;
  }
  100% {
    opacity: 0;
    height: 100%;
  }
}
@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@keyframes sideNavFoldedText {
  0% {
    max-width: 200px;
    opacity: 0.8;
  }
  100% {
    max-width: 0;
    opacity: 0;
  }
}
@keyframes placeholder-active {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
@keyframes moveLeftHalf {
  0% {
    transform: none;
  }
  100% {
    transform: translateX(-50%);
  }
}
@keyframes moveLeftHalf-vertical {
  0% {
    transform: none;
  }
  100% {
    transform: translateY(-50%);
  }
}
@font-face {
  font-family: 'rsuite-icon-font';
  src: url('https://cdn.jsdelivr.net/npm/rsuite/dist/styles/fonts/rsuite-icon-font.ttf') format('truetype'), url('https://cdn.jsdelivr.net/npm/rsuite/dist/styles/fonts/rsuite-icon-font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
.rs-btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  outline: 0 !important;
  white-space: nowrap;
  border: none;
  user-select: none;
  /* stylelint-disable */
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  /* stylelint-enable */
}
.rs-btn.rs-btn-ghost {
  padding-top: 7px;
  padding-bottom: 7px;
}
.rs-btn.rs-btn-icon {
  padding-left: 24px;
  height: 36px;
  position: relative;
}
.rs-btn-ghost.rs-btn.rs-btn-icon {
  padding-left: 22px;
}
.rs-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left {
  padding-left: 44px;
}
.rs-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right {
  padding-left: 12px;
  padding-right: 44px;
}
.rs-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon {
  left: auto;
  right: 0;
}
.rs-btn.rs-btn-icon > .rs-icon {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 36px;
  height: 36px;
  padding: 8px 0;
  font-size: 14px;
  line-height: 1.42857143;
}
.rs-btn-ghost.rs-btn.rs-btn-icon > .rs-icon {
  width: 34px;
  height: 34px;
  padding: 7px 0;
}
.rs-btn.rs-btn-icon > .rs-icon > svg {
  height: 14px;
  vertical-align: middle;
}
.rs-btn.rs-btn-icon-circle {
  width: 36px;
  padding: 8px;
}
.rs-btn:hover,
.rs-btn:focus {
  color: #191919;
  text-decoration: none;
}
.rs-btn:active,
.rs-btn.rs-btn-active {
  outline: 0;
  background-image: none;
}
.rs-btn.rs-btn-disabled {
  cursor: not-allowed !important;
  opacity: 0.3;
}
.rs-btn.rs-btn-disabled .rs-ripple-pond {
  display: none !important;
}
a.rs-btn {
  text-decoration: none;
}
.rs-btn-default,
.rs-btn-primary,
.rs-btn-subtle {
  overflow: hidden;
  position: relative;
}
@media not all and (min-resolution: 0.001dpcm) {
  .rs-btn-default,
  .rs-btn-primary,
  .rs-btn-subtle {
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    /* stylelint-disable-line property-no-vendor-prefix */
  }
}
.rs-btn-default:not(.rs-btn-disabled) .rs-ripple,
.rs-btn-primary:not(.rs-btn-disabled) .rs-ripple,
.rs-btn-subtle:not(.rs-btn-disabled) .rs-ripple {
  position: absolute;
  display: block;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  transform: scale(0);
  transition: 0s;
}
.rs-btn-default:not(.rs-btn-disabled) .rs-ripple-rippling,
.rs-btn-primary:not(.rs-btn-disabled) .rs-ripple-rippling,
.rs-btn-subtle:not(.rs-btn-disabled) .rs-ripple-rippling {
  transition: transform 0.55s cubic-bezier(0.4, 0, 0.2, 1), opacity 1s cubic-bezier(0.4, 0, 0.2, 1);
  transform: scale(1);
  opacity: 0;
}
.rs-btn-default:not(.rs-btn-disabled) .rs-ripple-pond,
.rs-btn-primary:not(.rs-btn-disabled) .rs-ripple-pond,
.rs-btn-subtle:not(.rs-btn-disabled) .rs-ripple-pond {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}
.rs-btn-loading {
  color: transparent !important;
  position: relative;
  cursor: default;
  pointer-events: none;
}
.rs-btn-loading > .rs-btn-spin::before,
.rs-btn-loading > .rs-btn-spin::after {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
}
.rs-btn-xs.rs-btn-loading > .rs-btn-spin::before,
.rs-btn-xs.rs-btn-loading > .rs-btn-spin::after {
  width: 16px;
  height: 16px;
}
.rs-btn-loading > .rs-btn-spin::before {
  border: 3px solid #f7f7fa;
}
.rs-btn-primary.rs-btn-loading > .rs-btn-spin::before {
  opacity: 0.3;
}
.rs-btn-loading > .rs-btn-spin::after {
  border-width: 3px;
  border-color: #a6a6a6 transparent transparent;
  border-style: solid;
  animation: buttonSpin 0.6s infinite linear;
}
.rs-btn-primary.rs-btn-loading > .rs-btn-spin::after {
  border-top-color: #fff;
}
.rs-btn-default {
  color: #191919;
  background: #f7f7fa;
}
.rs-btn-default.rs-btn-loading:hover,
.rs-btn-default.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-default.rs-btn-loading:focus,
.rs-btn-default.rs-btn-loading:active,
.rs-btn-default.rs-btn-loading:hover:active {
  color: #191919;
  background-color: #f7f7fa;
}
.rs-btn-default,
.rs-btn-default > .rs-icon {
  transition: color 0.2s linear, background-color 0.3s linear;
}
.rs-btn-default:focus {
  color: #191919;
  background-color: #e5e5ea;
}
.rs-btn-default:hover {
  color: #191919;
  background-color: #e5e5ea;
}
.rs-btn-default:active,
.rs-btn-default.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-default {
  color: #191919;
  background-color: #d9d9d9;
  background-image: none;
}
.rs-btn-default:active:hover,
.rs-btn-default.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-default:hover,
.rs-btn-default:active:focus,
.rs-btn-default.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-default:focus,
.rs-btn-default:active.focus,
.rs-btn-default.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-btn-default.focus {
  color: #191919;
  background-color: #d9d9d9;
}
.rs-btn-default.rs-btn-disabled,
.rs-dropdown-disabled .rs-btn-default,
.rs-btn-default.rs-btn-disabled:hover,
.rs-dropdown-disabled .rs-btn-default:hover,
.rs-btn-default.rs-btn-disabled:focus,
.rs-dropdown-disabled .rs-btn-default:focus,
.rs-btn-default.rs-btn-disabled:active,
.rs-dropdown-disabled .rs-btn-default:active,
.rs-btn-default.rs-btn-disabled:active:focus,
.rs-dropdown-disabled .rs-btn-default:active:focus {
  opacity: 1;
  color: #c5c6c7;
  background-color: #f7f7fa;
}
.rs-btn-default.rs-btn-red {
  /* stylelint-disable-next-line */
  color: #fff;
  background-color: #f44336;
}
.rs-btn-default.rs-btn-red.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #eb3626;
}
.rs-btn-default.rs-btn-red.rs-btn-loading:hover,
.rs-btn-default.rs-btn-red.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-default.rs-btn-red.rs-btn-loading:focus,
.rs-btn-default.rs-btn-red.rs-btn-loading:active,
.rs-btn-default.rs-btn-red.rs-btn-loading:hover:active {
  color: #fff;
  background-color: #f44336;
}
.rs-btn-default.rs-btn-red:focus,
.rs-btn-default.rs-btn-red.rs-btn-focus {
  color: #fff;
  background-color: #eb3626;
}
.rs-btn-default.rs-btn-red:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-red.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #d62915;
}
.rs-btn-default.rs-btn-red:hover {
  color: #fff;
}
.rs-btn-default.rs-btn-red:not(.rs-btn-disabled):hover {
  background-color: #eb3626;
}
.rs-btn-default.rs-btn-red:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #d62915;
}
.rs-btn-default.rs-btn-red:active,
.rs-btn-default.rs-btn-red.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-red {
  color: #fff;
  background-color: #d62915;
}
.rs-btn-default.rs-btn-red:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-red.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-red.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #b81c07;
}
.rs-btn-default.rs-btn-red:active:hover,
.rs-btn-default.rs-btn-red.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-red:hover,
.rs-btn-default.rs-btn-red:active:focus,
.rs-btn-default.rs-btn-red.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-red:focus,
.rs-btn-default.rs-btn-red:active.focus,
.rs-btn-default.rs-btn-red.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-red.focus {
  color: #fff;
  background-color: #d62915;
}
.rs-btn-default.rs-btn-red:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-red.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-red:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-red:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-red.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-red:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-red:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-red.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-red.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #b81c07;
}
.rs-btn-default.rs-btn-red:active,
.rs-btn-default.rs-btn-red.rs-btn-active,
.open > .rs-dropdown-toggle.rs-btn-default.rs-btn-red {
  background-image: none;
}
.rs-btn-default.rs-btn-red.rs-btn-disabled:hover,
.rs-btn-default.rs-btn-red.rs-btn-disabled:focus,
.rs-btn-default.rs-btn-red.rs-btn-disabled.focus {
  background: #f44336;
}
.rs-btn-default.rs-btn-red.rs-btn-default.rs-btn-disabled,
.rs-dropdown-disabled .rs-btn-default.rs-btn-red.rs-btn-default {
  opacity: 0.3;
}
.rs-btn-default.rs-btn-orange {
  /* stylelint-disable-next-line */
  color: #fff;
  background-color: #ff9800;
}
.rs-btn-default.rs-btn-orange.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #f59700;
}
.rs-btn-default.rs-btn-orange.rs-btn-loading:hover,
.rs-btn-default.rs-btn-orange.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-default.rs-btn-orange.rs-btn-loading:focus,
.rs-btn-default.rs-btn-orange.rs-btn-loading:active,
.rs-btn-default.rs-btn-orange.rs-btn-loading:hover:active {
  color: #fff;
  background-color: #ff9800;
}
.rs-btn-default.rs-btn-orange:focus,
.rs-btn-default.rs-btn-orange.rs-btn-focus {
  color: #fff;
  background-color: #f59700;
}
.rs-btn-default.rs-btn-orange:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-orange.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #e08e00;
}
.rs-btn-default.rs-btn-orange:hover {
  color: #fff;
}
.rs-btn-default.rs-btn-orange:not(.rs-btn-disabled):hover {
  background-color: #f59700;
}
.rs-btn-default.rs-btn-orange:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #e08e00;
}
.rs-btn-default.rs-btn-orange:active,
.rs-btn-default.rs-btn-orange.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-orange {
  color: #fff;
  background-color: #e08e00;
}
.rs-btn-default.rs-btn-orange:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-orange.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-orange.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #c27e00;
}
.rs-btn-default.rs-btn-orange:active:hover,
.rs-btn-default.rs-btn-orange.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-orange:hover,
.rs-btn-default.rs-btn-orange:active:focus,
.rs-btn-default.rs-btn-orange.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-orange:focus,
.rs-btn-default.rs-btn-orange:active.focus,
.rs-btn-default.rs-btn-orange.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-orange.focus {
  color: #fff;
  background-color: #e08e00;
}
.rs-btn-default.rs-btn-orange:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-orange.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-orange:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-orange:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-orange.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-orange:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-orange:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-orange.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-orange.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #c27e00;
}
.rs-btn-default.rs-btn-orange:active,
.rs-btn-default.rs-btn-orange.rs-btn-active,
.open > .rs-dropdown-toggle.rs-btn-default.rs-btn-orange {
  background-image: none;
}
.rs-btn-default.rs-btn-orange.rs-btn-disabled:hover,
.rs-btn-default.rs-btn-orange.rs-btn-disabled:focus,
.rs-btn-default.rs-btn-orange.rs-btn-disabled.focus {
  background: #ff9800;
}
.rs-btn-default.rs-btn-orange.rs-btn-default.rs-btn-disabled,
.rs-dropdown-disabled .rs-btn-default.rs-btn-orange.rs-btn-default {
  opacity: 0.3;
}
.rs-btn-default.rs-btn-yellow {
  /* stylelint-disable-next-line */
  color: #fff;
  background-color: #ffca28;
}
.rs-btn-default.rs-btn-yellow.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #f5c31d;
}
.rs-btn-default.rs-btn-yellow.rs-btn-loading:hover,
.rs-btn-default.rs-btn-yellow.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-default.rs-btn-yellow.rs-btn-loading:focus,
.rs-btn-default.rs-btn-yellow.rs-btn-loading:active,
.rs-btn-default.rs-btn-yellow.rs-btn-loading:hover:active {
  color: #fff;
  background-color: #ffca28;
}
.rs-btn-default.rs-btn-yellow:focus,
.rs-btn-default.rs-btn-yellow.rs-btn-focus {
  color: #fff;
  background-color: #f5c31d;
}
.rs-btn-default.rs-btn-yellow:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-yellow.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #e0b412;
}
.rs-btn-default.rs-btn-yellow:hover {
  color: #fff;
}
.rs-btn-default.rs-btn-yellow:not(.rs-btn-disabled):hover {
  background-color: #f5c31d;
}
.rs-btn-default.rs-btn-yellow:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #e0b412;
}
.rs-btn-default.rs-btn-yellow:active,
.rs-btn-default.rs-btn-yellow.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-yellow {
  color: #fff;
  background-color: #e0b412;
}
.rs-btn-default.rs-btn-yellow:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-yellow.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-yellow.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #c29d08;
}
.rs-btn-default.rs-btn-yellow:active:hover,
.rs-btn-default.rs-btn-yellow.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-yellow:hover,
.rs-btn-default.rs-btn-yellow:active:focus,
.rs-btn-default.rs-btn-yellow.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-yellow:focus,
.rs-btn-default.rs-btn-yellow:active.focus,
.rs-btn-default.rs-btn-yellow.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-yellow.focus {
  color: #fff;
  background-color: #e0b412;
}
.rs-btn-default.rs-btn-yellow:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-yellow.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-yellow:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-yellow:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-yellow.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-yellow:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-yellow:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-yellow.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-yellow.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #c29d08;
}
.rs-btn-default.rs-btn-yellow:active,
.rs-btn-default.rs-btn-yellow.rs-btn-active,
.open > .rs-dropdown-toggle.rs-btn-default.rs-btn-yellow {
  background-image: none;
}
.rs-btn-default.rs-btn-yellow.rs-btn-disabled:hover,
.rs-btn-default.rs-btn-yellow.rs-btn-disabled:focus,
.rs-btn-default.rs-btn-yellow.rs-btn-disabled.focus {
  background: #ffca28;
}
.rs-btn-default.rs-btn-yellow.rs-btn-default.rs-btn-disabled,
.rs-dropdown-disabled .rs-btn-default.rs-btn-yellow.rs-btn-default {
  opacity: 0.3;
}
.rs-btn-default.rs-btn-green {
  /* stylelint-disable-next-line */
  color: #fff;
  background-color: #4caf50;
}
.rs-btn-default.rs-btn-green.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #37ab3c;
}
.rs-btn-default.rs-btn-green.rs-btn-loading:hover,
.rs-btn-default.rs-btn-green.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-default.rs-btn-green.rs-btn-loading:focus,
.rs-btn-default.rs-btn-green.rs-btn-loading:active,
.rs-btn-default.rs-btn-green.rs-btn-loading:hover:active {
  color: #fff;
  background-color: #4caf50;
}
.rs-btn-default.rs-btn-green:focus,
.rs-btn-default.rs-btn-green.rs-btn-focus {
  color: #fff;
  background-color: #37ab3c;
}
.rs-btn-default.rs-btn-green:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-green.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #22a12a;
}
.rs-btn-default.rs-btn-green:hover {
  color: #fff;
}
.rs-btn-default.rs-btn-green:not(.rs-btn-disabled):hover {
  background-color: #37ab3c;
}
.rs-btn-default.rs-btn-green:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #22a12a;
}
.rs-btn-default.rs-btn-green:active,
.rs-btn-default.rs-btn-green.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-green {
  color: #fff;
  background-color: #22a12a;
}
.rs-btn-default.rs-btn-green:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-green.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-green.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #0f9119;
}
.rs-btn-default.rs-btn-green:active:hover,
.rs-btn-default.rs-btn-green.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-green:hover,
.rs-btn-default.rs-btn-green:active:focus,
.rs-btn-default.rs-btn-green.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-green:focus,
.rs-btn-default.rs-btn-green:active.focus,
.rs-btn-default.rs-btn-green.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-green.focus {
  color: #fff;
  background-color: #22a12a;
}
.rs-btn-default.rs-btn-green:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-green.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-green:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-green:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-green.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-green:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-green:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-green.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-green.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #0f9119;
}
.rs-btn-default.rs-btn-green:active,
.rs-btn-default.rs-btn-green.rs-btn-active,
.open > .rs-dropdown-toggle.rs-btn-default.rs-btn-green {
  background-image: none;
}
.rs-btn-default.rs-btn-green.rs-btn-disabled:hover,
.rs-btn-default.rs-btn-green.rs-btn-disabled:focus,
.rs-btn-default.rs-btn-green.rs-btn-disabled.focus {
  background: #4caf50;
}
.rs-btn-default.rs-btn-green.rs-btn-default.rs-btn-disabled,
.rs-dropdown-disabled .rs-btn-default.rs-btn-green.rs-btn-default {
  opacity: 0.3;
}
.rs-btn-default.rs-btn-cyan {
  /* stylelint-disable-next-line */
  color: #fff;
  background-color: #00bcd4;
}
.rs-btn-default.rs-btn-cyan.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #00b1cc;
}
.rs-btn-default.rs-btn-cyan.rs-btn-loading:hover,
.rs-btn-default.rs-btn-cyan.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-default.rs-btn-cyan.rs-btn-loading:focus,
.rs-btn-default.rs-btn-cyan.rs-btn-loading:active,
.rs-btn-default.rs-btn-cyan.rs-btn-loading:hover:active {
  color: #fff;
  background-color: #00bcd4;
}
.rs-btn-default.rs-btn-cyan:focus,
.rs-btn-default.rs-btn-cyan.rs-btn-focus {
  color: #fff;
  background-color: #00b1cc;
}
.rs-btn-default.rs-btn-cyan:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-cyan.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #00a0bd;
}
.rs-btn-default.rs-btn-cyan:hover {
  color: #fff;
}
.rs-btn-default.rs-btn-cyan:not(.rs-btn-disabled):hover {
  background-color: #00b1cc;
}
.rs-btn-default.rs-btn-cyan:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #00a0bd;
}
.rs-btn-default.rs-btn-cyan:active,
.rs-btn-default.rs-btn-cyan.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-cyan {
  color: #fff;
  background-color: #00a0bd;
}
.rs-btn-default.rs-btn-cyan:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-cyan.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-cyan.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #008aa6;
}
.rs-btn-default.rs-btn-cyan:active:hover,
.rs-btn-default.rs-btn-cyan.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-cyan:hover,
.rs-btn-default.rs-btn-cyan:active:focus,
.rs-btn-default.rs-btn-cyan.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-cyan:focus,
.rs-btn-default.rs-btn-cyan:active.focus,
.rs-btn-default.rs-btn-cyan.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-cyan.focus {
  color: #fff;
  background-color: #00a0bd;
}
.rs-btn-default.rs-btn-cyan:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-cyan.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-cyan:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-cyan:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-cyan.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-cyan:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-cyan:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-cyan.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-cyan.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #008aa6;
}
.rs-btn-default.rs-btn-cyan:active,
.rs-btn-default.rs-btn-cyan.rs-btn-active,
.open > .rs-dropdown-toggle.rs-btn-default.rs-btn-cyan {
  background-image: none;
}
.rs-btn-default.rs-btn-cyan.rs-btn-disabled:hover,
.rs-btn-default.rs-btn-cyan.rs-btn-disabled:focus,
.rs-btn-default.rs-btn-cyan.rs-btn-disabled.focus {
  background: #00bcd4;
}
.rs-btn-default.rs-btn-cyan.rs-btn-default.rs-btn-disabled,
.rs-dropdown-disabled .rs-btn-default.rs-btn-cyan.rs-btn-default {
  opacity: 0.3;
}
.rs-btn-default.rs-btn-blue {
  /* stylelint-disable-next-line */
  color: #fff;
  background-color: #2196f3;
}
.rs-btn-default.rs-btn-blue.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #1787e8;
}
.rs-btn-default.rs-btn-blue.rs-btn-loading:hover,
.rs-btn-default.rs-btn-blue.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-default.rs-btn-blue.rs-btn-loading:focus,
.rs-btn-default.rs-btn-blue.rs-btn-loading:active,
.rs-btn-default.rs-btn-blue.rs-btn-loading:hover:active {
  color: #fff;
  background-color: #2196f3;
}
.rs-btn-default.rs-btn-blue:focus,
.rs-btn-default.rs-btn-blue.rs-btn-focus {
  color: #fff;
  background-color: #1787e8;
}
.rs-btn-default.rs-btn-blue:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-blue.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #0d73d4;
}
.rs-btn-default.rs-btn-blue:hover {
  color: #fff;
}
.rs-btn-default.rs-btn-blue:not(.rs-btn-disabled):hover {
  background-color: #1787e8;
}
.rs-btn-default.rs-btn-blue:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #0d73d4;
}
.rs-btn-default.rs-btn-blue:active,
.rs-btn-default.rs-btn-blue.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-blue {
  color: #fff;
  background-color: #0d73d4;
}
.rs-btn-default.rs-btn-blue:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-blue.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-blue.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #045cb5;
}
.rs-btn-default.rs-btn-blue:active:hover,
.rs-btn-default.rs-btn-blue.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-blue:hover,
.rs-btn-default.rs-btn-blue:active:focus,
.rs-btn-default.rs-btn-blue.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-blue:focus,
.rs-btn-default.rs-btn-blue:active.focus,
.rs-btn-default.rs-btn-blue.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-blue.focus {
  color: #fff;
  background-color: #0d73d4;
}
.rs-btn-default.rs-btn-blue:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-blue.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-blue:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-blue:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-blue.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-blue:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-blue:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-blue.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-blue.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #045cb5;
}
.rs-btn-default.rs-btn-blue:active,
.rs-btn-default.rs-btn-blue.rs-btn-active,
.open > .rs-dropdown-toggle.rs-btn-default.rs-btn-blue {
  background-image: none;
}
.rs-btn-default.rs-btn-blue.rs-btn-disabled:hover,
.rs-btn-default.rs-btn-blue.rs-btn-disabled:focus,
.rs-btn-default.rs-btn-blue.rs-btn-disabled.focus {
  background: #2196f3;
}
.rs-btn-default.rs-btn-blue.rs-btn-default.rs-btn-disabled,
.rs-dropdown-disabled .rs-btn-default.rs-btn-blue.rs-btn-default {
  opacity: 0.3;
}
.rs-btn-default.rs-btn-violet {
  /* stylelint-disable-next-line */
  color: #fff;
  background-color: #673ab7;
}
.rs-btn-default.rs-btn-violet.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #5f2bb3;
}
.rs-btn-default.rs-btn-violet.rs-btn-loading:hover,
.rs-btn-default.rs-btn-violet.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-default.rs-btn-violet.rs-btn-loading:focus,
.rs-btn-default.rs-btn-violet.rs-btn-loading:active,
.rs-btn-default.rs-btn-violet.rs-btn-loading:hover:active {
  color: #fff;
  background-color: #673ab7;
}
.rs-btn-default.rs-btn-violet:focus,
.rs-btn-default.rs-btn-violet.rs-btn-focus {
  color: #fff;
  background-color: #5f2bb3;
}
.rs-btn-default.rs-btn-violet:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-violet.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #531ba8;
}
.rs-btn-default.rs-btn-violet:hover {
  color: #fff;
}
.rs-btn-default.rs-btn-violet:not(.rs-btn-disabled):hover {
  background-color: #5f2bb3;
}
.rs-btn-default.rs-btn-violet:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #531ba8;
}
.rs-btn-default.rs-btn-violet:active,
.rs-btn-default.rs-btn-violet.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-violet {
  color: #fff;
  background-color: #531ba8;
}
.rs-btn-default.rs-btn-violet:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-violet.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-violet.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #470c99;
}
.rs-btn-default.rs-btn-violet:active:hover,
.rs-btn-default.rs-btn-violet.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-violet:hover,
.rs-btn-default.rs-btn-violet:active:focus,
.rs-btn-default.rs-btn-violet.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-violet:focus,
.rs-btn-default.rs-btn-violet:active.focus,
.rs-btn-default.rs-btn-violet.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-violet.focus {
  color: #fff;
  background-color: #531ba8;
}
.rs-btn-default.rs-btn-violet:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-violet.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-violet:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-violet:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-violet.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-violet:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-violet:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-default.rs-btn-violet.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-violet.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #470c99;
}
.rs-btn-default.rs-btn-violet:active,
.rs-btn-default.rs-btn-violet.rs-btn-active,
.open > .rs-dropdown-toggle.rs-btn-default.rs-btn-violet {
  background-image: none;
}
.rs-btn-default.rs-btn-violet.rs-btn-disabled:hover,
.rs-btn-default.rs-btn-violet.rs-btn-disabled:focus,
.rs-btn-default.rs-btn-violet.rs-btn-disabled.focus {
  background: #673ab7;
}
.rs-btn-default.rs-btn-violet.rs-btn-default.rs-btn-disabled,
.rs-dropdown-disabled .rs-btn-default.rs-btn-violet.rs-btn-default {
  opacity: 0.3;
}
.rs-btn-primary {
  /* stylelint-disable-next-line */
  color: #ffffff;
  background-color: #D40511;
}
.rs-btn-primary.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #cc0209;
}
.rs-btn-primary.rs-btn-loading:hover,
.rs-btn-primary.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-primary.rs-btn-loading:focus,
.rs-btn-primary.rs-btn-loading:active,
.rs-btn-primary.rs-btn-loading:hover:active {
  color: #ffffff;
  background-color: #D40511;
}
.rs-btn-primary:focus,
.rs-btn-primary.rs-btn-focus {
  color: #ffffff;
  background-color: #cc0209;
}
.rs-btn-primary:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #bd0003;
}
.rs-btn-primary:hover {
  color: #ffffff;
}
.rs-btn-primary:not(.rs-btn-disabled):hover {
  background-color: #cc0209;
}
.rs-btn-primary:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #bd0003;
}
.rs-btn-primary:active,
.rs-btn-primary.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-primary {
  color: #ffffff;
  background-color: #bd0003;
}
.rs-btn-primary:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #a60000;
}
.rs-btn-primary:active:hover,
.rs-btn-primary.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-primary:hover,
.rs-btn-primary:active:focus,
.rs-btn-primary.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-primary:focus,
.rs-btn-primary:active.focus,
.rs-btn-primary.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.focus {
  color: #ffffff;
  background-color: #bd0003;
}
.rs-btn-primary:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #a60000;
}
.rs-btn-primary:active,
.rs-btn-primary.rs-btn-active,
.open > .rs-dropdown-toggle.rs-btn-primary {
  background-image: none;
}
.rs-btn-primary.rs-btn-disabled:hover,
.rs-btn-primary.rs-btn-disabled:focus,
.rs-btn-primary.rs-btn-disabled.focus {
  background: #D40511;
}
.rs-btn-primary.rs-btn-loading:hover,
.rs-btn-primary.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-primary.rs-btn-loading:focus,
.rs-btn-primary.rs-btn-loading:active,
.rs-btn-primary.rs-btn-loading:hover:active {
  color: #fff;
  background-color: #D40511;
}
.rs-btn-primary,
.rs-btn-primary > .rs-icon {
  transition: color 0.2s linear, background-color 0.3s linear;
}
.rs-btn-primary.rs-btn-red {
  /* stylelint-disable-next-line */
  color: #fff;
  background-color: #f44336;
}
.rs-btn-primary.rs-btn-red.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #eb3626;
}
.rs-btn-primary.rs-btn-red.rs-btn-loading:hover,
.rs-btn-primary.rs-btn-red.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-primary.rs-btn-red.rs-btn-loading:focus,
.rs-btn-primary.rs-btn-red.rs-btn-loading:active,
.rs-btn-primary.rs-btn-red.rs-btn-loading:hover:active {
  color: #fff;
  background-color: #f44336;
}
.rs-btn-primary.rs-btn-red:focus,
.rs-btn-primary.rs-btn-red.rs-btn-focus {
  color: #fff;
  background-color: #eb3626;
}
.rs-btn-primary.rs-btn-red:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-red.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #d62915;
}
.rs-btn-primary.rs-btn-red:hover {
  color: #fff;
}
.rs-btn-primary.rs-btn-red:not(.rs-btn-disabled):hover {
  background-color: #eb3626;
}
.rs-btn-primary.rs-btn-red:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #d62915;
}
.rs-btn-primary.rs-btn-red:active,
.rs-btn-primary.rs-btn-red.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-red {
  color: #fff;
  background-color: #d62915;
}
.rs-btn-primary.rs-btn-red:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-red.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-red.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #b81c07;
}
.rs-btn-primary.rs-btn-red:active:hover,
.rs-btn-primary.rs-btn-red.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-red:hover,
.rs-btn-primary.rs-btn-red:active:focus,
.rs-btn-primary.rs-btn-red.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-red:focus,
.rs-btn-primary.rs-btn-red:active.focus,
.rs-btn-primary.rs-btn-red.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-red.focus {
  color: #fff;
  background-color: #d62915;
}
.rs-btn-primary.rs-btn-red:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-red.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-red:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-red:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-red.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-red:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-red:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-red.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-red.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #b81c07;
}
.rs-btn-primary.rs-btn-red:active,
.rs-btn-primary.rs-btn-red.rs-btn-active,
.open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-red {
  background-image: none;
}
.rs-btn-primary.rs-btn-red.rs-btn-disabled:hover,
.rs-btn-primary.rs-btn-red.rs-btn-disabled:focus,
.rs-btn-primary.rs-btn-red.rs-btn-disabled.focus {
  background: #f44336;
}
.rs-btn-primary.rs-btn-red.rs-btn-default.rs-btn-disabled,
.rs-dropdown-disabled .rs-btn-primary.rs-btn-red.rs-btn-default {
  opacity: 0.3;
}
.rs-btn-primary.rs-btn-orange {
  /* stylelint-disable-next-line */
  color: #fff;
  background-color: #ff9800;
}
.rs-btn-primary.rs-btn-orange.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #f59700;
}
.rs-btn-primary.rs-btn-orange.rs-btn-loading:hover,
.rs-btn-primary.rs-btn-orange.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-primary.rs-btn-orange.rs-btn-loading:focus,
.rs-btn-primary.rs-btn-orange.rs-btn-loading:active,
.rs-btn-primary.rs-btn-orange.rs-btn-loading:hover:active {
  color: #fff;
  background-color: #ff9800;
}
.rs-btn-primary.rs-btn-orange:focus,
.rs-btn-primary.rs-btn-orange.rs-btn-focus {
  color: #fff;
  background-color: #f59700;
}
.rs-btn-primary.rs-btn-orange:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-orange.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #e08e00;
}
.rs-btn-primary.rs-btn-orange:hover {
  color: #fff;
}
.rs-btn-primary.rs-btn-orange:not(.rs-btn-disabled):hover {
  background-color: #f59700;
}
.rs-btn-primary.rs-btn-orange:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #e08e00;
}
.rs-btn-primary.rs-btn-orange:active,
.rs-btn-primary.rs-btn-orange.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-orange {
  color: #fff;
  background-color: #e08e00;
}
.rs-btn-primary.rs-btn-orange:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-orange.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-orange.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #c27e00;
}
.rs-btn-primary.rs-btn-orange:active:hover,
.rs-btn-primary.rs-btn-orange.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-orange:hover,
.rs-btn-primary.rs-btn-orange:active:focus,
.rs-btn-primary.rs-btn-orange.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-orange:focus,
.rs-btn-primary.rs-btn-orange:active.focus,
.rs-btn-primary.rs-btn-orange.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-orange.focus {
  color: #fff;
  background-color: #e08e00;
}
.rs-btn-primary.rs-btn-orange:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-orange.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-orange:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-orange:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-orange.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-orange:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-orange:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-orange.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-orange.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #c27e00;
}
.rs-btn-primary.rs-btn-orange:active,
.rs-btn-primary.rs-btn-orange.rs-btn-active,
.open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-orange {
  background-image: none;
}
.rs-btn-primary.rs-btn-orange.rs-btn-disabled:hover,
.rs-btn-primary.rs-btn-orange.rs-btn-disabled:focus,
.rs-btn-primary.rs-btn-orange.rs-btn-disabled.focus {
  background: #ff9800;
}
.rs-btn-primary.rs-btn-orange.rs-btn-default.rs-btn-disabled,
.rs-dropdown-disabled .rs-btn-primary.rs-btn-orange.rs-btn-default {
  opacity: 0.3;
}
.rs-btn-primary.rs-btn-yellow {
  /* stylelint-disable-next-line */
  color: #fff;
  background-color: #ffca28;
}
.rs-btn-primary.rs-btn-yellow.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #f5c31d;
}
.rs-btn-primary.rs-btn-yellow.rs-btn-loading:hover,
.rs-btn-primary.rs-btn-yellow.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-primary.rs-btn-yellow.rs-btn-loading:focus,
.rs-btn-primary.rs-btn-yellow.rs-btn-loading:active,
.rs-btn-primary.rs-btn-yellow.rs-btn-loading:hover:active {
  color: #fff;
  background-color: #ffca28;
}
.rs-btn-primary.rs-btn-yellow:focus,
.rs-btn-primary.rs-btn-yellow.rs-btn-focus {
  color: #fff;
  background-color: #f5c31d;
}
.rs-btn-primary.rs-btn-yellow:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-yellow.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #e0b412;
}
.rs-btn-primary.rs-btn-yellow:hover {
  color: #fff;
}
.rs-btn-primary.rs-btn-yellow:not(.rs-btn-disabled):hover {
  background-color: #f5c31d;
}
.rs-btn-primary.rs-btn-yellow:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #e0b412;
}
.rs-btn-primary.rs-btn-yellow:active,
.rs-btn-primary.rs-btn-yellow.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-yellow {
  color: #fff;
  background-color: #e0b412;
}
.rs-btn-primary.rs-btn-yellow:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-yellow.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-yellow.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #c29d08;
}
.rs-btn-primary.rs-btn-yellow:active:hover,
.rs-btn-primary.rs-btn-yellow.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-yellow:hover,
.rs-btn-primary.rs-btn-yellow:active:focus,
.rs-btn-primary.rs-btn-yellow.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-yellow:focus,
.rs-btn-primary.rs-btn-yellow:active.focus,
.rs-btn-primary.rs-btn-yellow.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-yellow.focus {
  color: #fff;
  background-color: #e0b412;
}
.rs-btn-primary.rs-btn-yellow:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-yellow.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-yellow:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-yellow:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-yellow.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-yellow:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-yellow:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-yellow.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-yellow.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #c29d08;
}
.rs-btn-primary.rs-btn-yellow:active,
.rs-btn-primary.rs-btn-yellow.rs-btn-active,
.open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-yellow {
  background-image: none;
}
.rs-btn-primary.rs-btn-yellow.rs-btn-disabled:hover,
.rs-btn-primary.rs-btn-yellow.rs-btn-disabled:focus,
.rs-btn-primary.rs-btn-yellow.rs-btn-disabled.focus {
  background: #ffca28;
}
.rs-btn-primary.rs-btn-yellow.rs-btn-default.rs-btn-disabled,
.rs-dropdown-disabled .rs-btn-primary.rs-btn-yellow.rs-btn-default {
  opacity: 0.3;
}
.rs-btn-primary.rs-btn-green {
  /* stylelint-disable-next-line */
  color: #fff;
  background-color: #4caf50;
}
.rs-btn-primary.rs-btn-green.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #37ab3c;
}
.rs-btn-primary.rs-btn-green.rs-btn-loading:hover,
.rs-btn-primary.rs-btn-green.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-primary.rs-btn-green.rs-btn-loading:focus,
.rs-btn-primary.rs-btn-green.rs-btn-loading:active,
.rs-btn-primary.rs-btn-green.rs-btn-loading:hover:active {
  color: #fff;
  background-color: #4caf50;
}
.rs-btn-primary.rs-btn-green:focus,
.rs-btn-primary.rs-btn-green.rs-btn-focus {
  color: #fff;
  background-color: #37ab3c;
}
.rs-btn-primary.rs-btn-green:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-green.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #22a12a;
}
.rs-btn-primary.rs-btn-green:hover {
  color: #fff;
}
.rs-btn-primary.rs-btn-green:not(.rs-btn-disabled):hover {
  background-color: #37ab3c;
}
.rs-btn-primary.rs-btn-green:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #22a12a;
}
.rs-btn-primary.rs-btn-green:active,
.rs-btn-primary.rs-btn-green.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-green {
  color: #fff;
  background-color: #22a12a;
}
.rs-btn-primary.rs-btn-green:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-green.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-green.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #0f9119;
}
.rs-btn-primary.rs-btn-green:active:hover,
.rs-btn-primary.rs-btn-green.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-green:hover,
.rs-btn-primary.rs-btn-green:active:focus,
.rs-btn-primary.rs-btn-green.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-green:focus,
.rs-btn-primary.rs-btn-green:active.focus,
.rs-btn-primary.rs-btn-green.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-green.focus {
  color: #fff;
  background-color: #22a12a;
}
.rs-btn-primary.rs-btn-green:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-green.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-green:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-green:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-green.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-green:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-green:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-green.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-green.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #0f9119;
}
.rs-btn-primary.rs-btn-green:active,
.rs-btn-primary.rs-btn-green.rs-btn-active,
.open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-green {
  background-image: none;
}
.rs-btn-primary.rs-btn-green.rs-btn-disabled:hover,
.rs-btn-primary.rs-btn-green.rs-btn-disabled:focus,
.rs-btn-primary.rs-btn-green.rs-btn-disabled.focus {
  background: #4caf50;
}
.rs-btn-primary.rs-btn-green.rs-btn-default.rs-btn-disabled,
.rs-dropdown-disabled .rs-btn-primary.rs-btn-green.rs-btn-default {
  opacity: 0.3;
}
.rs-btn-primary.rs-btn-cyan {
  /* stylelint-disable-next-line */
  color: #fff;
  background-color: #00bcd4;
}
.rs-btn-primary.rs-btn-cyan.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #00b1cc;
}
.rs-btn-primary.rs-btn-cyan.rs-btn-loading:hover,
.rs-btn-primary.rs-btn-cyan.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-primary.rs-btn-cyan.rs-btn-loading:focus,
.rs-btn-primary.rs-btn-cyan.rs-btn-loading:active,
.rs-btn-primary.rs-btn-cyan.rs-btn-loading:hover:active {
  color: #fff;
  background-color: #00bcd4;
}
.rs-btn-primary.rs-btn-cyan:focus,
.rs-btn-primary.rs-btn-cyan.rs-btn-focus {
  color: #fff;
  background-color: #00b1cc;
}
.rs-btn-primary.rs-btn-cyan:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-cyan.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #00a0bd;
}
.rs-btn-primary.rs-btn-cyan:hover {
  color: #fff;
}
.rs-btn-primary.rs-btn-cyan:not(.rs-btn-disabled):hover {
  background-color: #00b1cc;
}
.rs-btn-primary.rs-btn-cyan:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #00a0bd;
}
.rs-btn-primary.rs-btn-cyan:active,
.rs-btn-primary.rs-btn-cyan.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-cyan {
  color: #fff;
  background-color: #00a0bd;
}
.rs-btn-primary.rs-btn-cyan:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-cyan.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-cyan.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #008aa6;
}
.rs-btn-primary.rs-btn-cyan:active:hover,
.rs-btn-primary.rs-btn-cyan.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-cyan:hover,
.rs-btn-primary.rs-btn-cyan:active:focus,
.rs-btn-primary.rs-btn-cyan.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-cyan:focus,
.rs-btn-primary.rs-btn-cyan:active.focus,
.rs-btn-primary.rs-btn-cyan.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-cyan.focus {
  color: #fff;
  background-color: #00a0bd;
}
.rs-btn-primary.rs-btn-cyan:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-cyan.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-cyan:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-cyan:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-cyan.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-cyan:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-cyan:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-cyan.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-cyan.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #008aa6;
}
.rs-btn-primary.rs-btn-cyan:active,
.rs-btn-primary.rs-btn-cyan.rs-btn-active,
.open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-cyan {
  background-image: none;
}
.rs-btn-primary.rs-btn-cyan.rs-btn-disabled:hover,
.rs-btn-primary.rs-btn-cyan.rs-btn-disabled:focus,
.rs-btn-primary.rs-btn-cyan.rs-btn-disabled.focus {
  background: #00bcd4;
}
.rs-btn-primary.rs-btn-cyan.rs-btn-default.rs-btn-disabled,
.rs-dropdown-disabled .rs-btn-primary.rs-btn-cyan.rs-btn-default {
  opacity: 0.3;
}
.rs-btn-primary.rs-btn-blue {
  /* stylelint-disable-next-line */
  color: #fff;
  background-color: #2196f3;
}
.rs-btn-primary.rs-btn-blue.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #1787e8;
}
.rs-btn-primary.rs-btn-blue.rs-btn-loading:hover,
.rs-btn-primary.rs-btn-blue.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-primary.rs-btn-blue.rs-btn-loading:focus,
.rs-btn-primary.rs-btn-blue.rs-btn-loading:active,
.rs-btn-primary.rs-btn-blue.rs-btn-loading:hover:active {
  color: #fff;
  background-color: #2196f3;
}
.rs-btn-primary.rs-btn-blue:focus,
.rs-btn-primary.rs-btn-blue.rs-btn-focus {
  color: #fff;
  background-color: #1787e8;
}
.rs-btn-primary.rs-btn-blue:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-blue.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #0d73d4;
}
.rs-btn-primary.rs-btn-blue:hover {
  color: #fff;
}
.rs-btn-primary.rs-btn-blue:not(.rs-btn-disabled):hover {
  background-color: #1787e8;
}
.rs-btn-primary.rs-btn-blue:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #0d73d4;
}
.rs-btn-primary.rs-btn-blue:active,
.rs-btn-primary.rs-btn-blue.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-blue {
  color: #fff;
  background-color: #0d73d4;
}
.rs-btn-primary.rs-btn-blue:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-blue.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-blue.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #045cb5;
}
.rs-btn-primary.rs-btn-blue:active:hover,
.rs-btn-primary.rs-btn-blue.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-blue:hover,
.rs-btn-primary.rs-btn-blue:active:focus,
.rs-btn-primary.rs-btn-blue.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-blue:focus,
.rs-btn-primary.rs-btn-blue:active.focus,
.rs-btn-primary.rs-btn-blue.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-blue.focus {
  color: #fff;
  background-color: #0d73d4;
}
.rs-btn-primary.rs-btn-blue:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-blue.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-blue:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-blue:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-blue.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-blue:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-blue:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-blue.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-blue.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #045cb5;
}
.rs-btn-primary.rs-btn-blue:active,
.rs-btn-primary.rs-btn-blue.rs-btn-active,
.open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-blue {
  background-image: none;
}
.rs-btn-primary.rs-btn-blue.rs-btn-disabled:hover,
.rs-btn-primary.rs-btn-blue.rs-btn-disabled:focus,
.rs-btn-primary.rs-btn-blue.rs-btn-disabled.focus {
  background: #2196f3;
}
.rs-btn-primary.rs-btn-blue.rs-btn-default.rs-btn-disabled,
.rs-dropdown-disabled .rs-btn-primary.rs-btn-blue.rs-btn-default {
  opacity: 0.3;
}
.rs-btn-primary.rs-btn-violet {
  /* stylelint-disable-next-line */
  color: #fff;
  background-color: #673ab7;
}
.rs-btn-primary.rs-btn-violet.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #5f2bb3;
}
.rs-btn-primary.rs-btn-violet.rs-btn-loading:hover,
.rs-btn-primary.rs-btn-violet.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-primary.rs-btn-violet.rs-btn-loading:focus,
.rs-btn-primary.rs-btn-violet.rs-btn-loading:active,
.rs-btn-primary.rs-btn-violet.rs-btn-loading:hover:active {
  color: #fff;
  background-color: #673ab7;
}
.rs-btn-primary.rs-btn-violet:focus,
.rs-btn-primary.rs-btn-violet.rs-btn-focus {
  color: #fff;
  background-color: #5f2bb3;
}
.rs-btn-primary.rs-btn-violet:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-violet.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #531ba8;
}
.rs-btn-primary.rs-btn-violet:hover {
  color: #fff;
}
.rs-btn-primary.rs-btn-violet:not(.rs-btn-disabled):hover {
  background-color: #5f2bb3;
}
.rs-btn-primary.rs-btn-violet:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #531ba8;
}
.rs-btn-primary.rs-btn-violet:active,
.rs-btn-primary.rs-btn-violet.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-violet {
  color: #fff;
  background-color: #531ba8;
}
.rs-btn-primary.rs-btn-violet:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-violet.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-violet.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #470c99;
}
.rs-btn-primary.rs-btn-violet:active:hover,
.rs-btn-primary.rs-btn-violet.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-violet:hover,
.rs-btn-primary.rs-btn-violet:active:focus,
.rs-btn-primary.rs-btn-violet.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-violet:focus,
.rs-btn-primary.rs-btn-violet:active.focus,
.rs-btn-primary.rs-btn-violet.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-violet.focus {
  color: #fff;
  background-color: #531ba8;
}
.rs-btn-primary.rs-btn-violet:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-violet.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-violet:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-violet:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-violet.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-violet:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-violet:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-violet.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-violet.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: #470c99;
}
.rs-btn-primary.rs-btn-violet:active,
.rs-btn-primary.rs-btn-violet.rs-btn-active,
.open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-violet {
  background-image: none;
}
.rs-btn-primary.rs-btn-violet.rs-btn-disabled:hover,
.rs-btn-primary.rs-btn-violet.rs-btn-disabled:focus,
.rs-btn-primary.rs-btn-violet.rs-btn-disabled.focus {
  background: #673ab7;
}
.rs-btn-primary.rs-btn-violet.rs-btn-default.rs-btn-disabled,
.rs-dropdown-disabled .rs-btn-primary.rs-btn-violet.rs-btn-default {
  opacity: 0.3;
}
.rs-btn-link {
  transition: color 0.2s linear;
  color: #bd0003;
  font-weight: normal;
  border-radius: 0;
}
.rs-btn-link.rs-btn-loading:hover,
.rs-btn-link.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-link.rs-btn-loading:focus,
.rs-btn-link.rs-btn-loading:active,
.rs-btn-link.rs-btn-loading:hover:active {
  color: #bd0003;
  background-color: transparent;
}
.rs-btn-link,
.rs-btn-link:active,
.rs-btn-link.rs-btn-active {
  background-color: transparent;
}
.rs-btn-link,
.rs-btn-link:hover,
.rs-btn-link:focus,
.rs-btn-link:active {
  border-color: transparent;
}
.rs-btn-link:hover,
.rs-btn-link:focus {
  color: #a60000;
  text-decoration: underline;
  background-color: transparent;
}
.rs-btn-link:active,
.rs-btn-link.rs-btn-active {
  color: #870200;
}
.rs-btn-link:active:hover,
.rs-btn-link.rs-btn-active:hover,
.rs-btn-link:active:focus,
.rs-btn-link.rs-btn-active:focus,
.rs-btn-link:active.focus,
.rs-btn-link.rs-btn-active.focus {
  color: #870200;
}
.rs-btn-link.rs-btn-disabled:hover,
.rs-btn-link.rs-btn-disabled:focus {
  color: #bd0003;
  text-decoration: none;
}
.rs-btn-link.rs-btn-loading:hover,
.rs-btn-link.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-link.rs-btn-loading:focus,
.rs-btn-link.rs-btn-loading:active,
.rs-btn-link.rs-btn-loading:hover:active {
  color: #D40511;
  background-color: transparent;
}
.rs-btn-link.rs-btn-red {
  color: #bf2008;
  font-weight: normal;
  border-radius: 0;
}
.rs-btn-link.rs-btn-red.rs-btn-loading:hover,
.rs-btn-link.rs-btn-red.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-link.rs-btn-red.rs-btn-loading:focus,
.rs-btn-link.rs-btn-red.rs-btn-loading:active,
.rs-btn-link.rs-btn-red.rs-btn-loading:hover:active {
  color: #bf2008;
  background-color: transparent;
}
.rs-btn-link.rs-btn-red,
.rs-btn-link.rs-btn-red:active,
.rs-btn-link.rs-btn-red.rs-btn-active {
  background-color: transparent;
}
.rs-btn-link.rs-btn-red,
.rs-btn-link.rs-btn-red:hover,
.rs-btn-link.rs-btn-red:focus,
.rs-btn-link.rs-btn-red:active {
  border-color: transparent;
}
.rs-btn-link.rs-btn-red:hover,
.rs-btn-link.rs-btn-red:focus {
  color: #a81b02;
  text-decoration: underline;
  background-color: transparent;
}
.rs-btn-link.rs-btn-red:active,
.rs-btn-link.rs-btn-red.rs-btn-active {
  color: #8a1700;
}
.rs-btn-link.rs-btn-red:active:hover,
.rs-btn-link.rs-btn-red.rs-btn-active:hover,
.rs-btn-link.rs-btn-red:active:focus,
.rs-btn-link.rs-btn-red.rs-btn-active:focus,
.rs-btn-link.rs-btn-red:active.focus,
.rs-btn-link.rs-btn-red.rs-btn-active.focus {
  color: #8a1700;
}
.rs-btn-link.rs-btn-red.rs-btn-disabled:hover,
.rs-btn-link.rs-btn-red.rs-btn-disabled:focus {
  color: #bf2008;
  text-decoration: none;
}
.rs-btn-link.rs-btn-orange {
  color: #c98600;
  font-weight: normal;
  border-radius: 0;
}
.rs-btn-link.rs-btn-orange.rs-btn-loading:hover,
.rs-btn-link.rs-btn-orange.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-link.rs-btn-orange.rs-btn-loading:focus,
.rs-btn-link.rs-btn-orange.rs-btn-loading:active,
.rs-btn-link.rs-btn-orange.rs-btn-loading:hover:active {
  color: #c98600;
  background-color: transparent;
}
.rs-btn-link.rs-btn-orange,
.rs-btn-link.rs-btn-orange:active,
.rs-btn-link.rs-btn-orange.rs-btn-active {
  background-color: transparent;
}
.rs-btn-link.rs-btn-orange,
.rs-btn-link.rs-btn-orange:hover,
.rs-btn-link.rs-btn-orange:focus,
.rs-btn-link.rs-btn-orange:active {
  border-color: transparent;
}
.rs-btn-link.rs-btn-orange:hover,
.rs-btn-link.rs-btn-orange:focus {
  color: #b37a00;
  text-decoration: underline;
  background-color: transparent;
}
.rs-btn-link.rs-btn-orange:active,
.rs-btn-link.rs-btn-orange.rs-btn-active {
  color: #946800;
}
.rs-btn-link.rs-btn-orange:active:hover,
.rs-btn-link.rs-btn-orange.rs-btn-active:hover,
.rs-btn-link.rs-btn-orange:active:focus,
.rs-btn-link.rs-btn-orange.rs-btn-active:focus,
.rs-btn-link.rs-btn-orange:active.focus,
.rs-btn-link.rs-btn-orange.rs-btn-active.focus {
  color: #946800;
}
.rs-btn-link.rs-btn-orange.rs-btn-disabled:hover,
.rs-btn-link.rs-btn-orange.rs-btn-disabled:focus {
  color: #c98600;
  text-decoration: none;
}
.rs-btn-link.rs-btn-yellow {
  color: #c9a608;
  font-weight: normal;
  border-radius: 0;
}
.rs-btn-link.rs-btn-yellow.rs-btn-loading:hover,
.rs-btn-link.rs-btn-yellow.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-link.rs-btn-yellow.rs-btn-loading:focus,
.rs-btn-link.rs-btn-yellow.rs-btn-loading:active,
.rs-btn-link.rs-btn-yellow.rs-btn-loading:hover:active {
  color: #c9a608;
  background-color: transparent;
}
.rs-btn-link.rs-btn-yellow,
.rs-btn-link.rs-btn-yellow:active,
.rs-btn-link.rs-btn-yellow.rs-btn-active {
  background-color: transparent;
}
.rs-btn-link.rs-btn-yellow,
.rs-btn-link.rs-btn-yellow:hover,
.rs-btn-link.rs-btn-yellow:focus,
.rs-btn-link.rs-btn-yellow:active {
  border-color: transparent;
}
.rs-btn-link.rs-btn-yellow:hover,
.rs-btn-link.rs-btn-yellow:focus {
  color: #b39504;
  text-decoration: underline;
  background-color: transparent;
}
.rs-btn-link.rs-btn-yellow:active,
.rs-btn-link.rs-btn-yellow.rs-btn-active {
  color: #947e00;
}
.rs-btn-link.rs-btn-yellow:active:hover,
.rs-btn-link.rs-btn-yellow.rs-btn-active:hover,
.rs-btn-link.rs-btn-yellow:active:focus,
.rs-btn-link.rs-btn-yellow.rs-btn-active:focus,
.rs-btn-link.rs-btn-yellow:active.focus,
.rs-btn-link.rs-btn-yellow.rs-btn-active.focus {
  color: #947e00;
}
.rs-btn-link.rs-btn-yellow.rs-btn-disabled:hover,
.rs-btn-link.rs-btn-yellow.rs-btn-disabled:focus {
  color: #c9a608;
  text-decoration: none;
}
.rs-btn-link.rs-btn-green {
  color: #10911d;
  font-weight: normal;
  border-radius: 0;
}
.rs-btn-link.rs-btn-green.rs-btn-loading:hover,
.rs-btn-link.rs-btn-green.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-link.rs-btn-green.rs-btn-loading:focus,
.rs-btn-link.rs-btn-green.rs-btn-loading:active,
.rs-btn-link.rs-btn-green.rs-btn-loading:hover:active {
  color: #10911d;
  background-color: transparent;
}
.rs-btn-link.rs-btn-green,
.rs-btn-link.rs-btn-green:active,
.rs-btn-link.rs-btn-green.rs-btn-active {
  background-color: transparent;
}
.rs-btn-link.rs-btn-green,
.rs-btn-link.rs-btn-green:hover,
.rs-btn-link.rs-btn-green:focus,
.rs-btn-link.rs-btn-green:active {
  border-color: transparent;
}
.rs-btn-link.rs-btn-green:hover,
.rs-btn-link.rs-btn-green:focus {
  color: #088216;
  text-decoration: underline;
  background-color: transparent;
}
.rs-btn-link.rs-btn-green:active,
.rs-btn-link.rs-btn-green.rs-btn-active {
  color: #016e10;
}
.rs-btn-link.rs-btn-green:active:hover,
.rs-btn-link.rs-btn-green.rs-btn-active:hover,
.rs-btn-link.rs-btn-green:active:focus,
.rs-btn-link.rs-btn-green.rs-btn-active:focus,
.rs-btn-link.rs-btn-green:active.focus,
.rs-btn-link.rs-btn-green.rs-btn-active.focus {
  color: #016e10;
}
.rs-btn-link.rs-btn-green.rs-btn-disabled:hover,
.rs-btn-link.rs-btn-green.rs-btn-disabled:focus {
  color: #10911d;
  text-decoration: none;
}
.rs-btn-link.rs-btn-cyan {
  color: #0087a6;
  font-weight: normal;
  border-radius: 0;
}
.rs-btn-link.rs-btn-cyan.rs-btn-loading:hover,
.rs-btn-link.rs-btn-cyan.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-link.rs-btn-cyan.rs-btn-loading:focus,
.rs-btn-link.rs-btn-cyan.rs-btn-loading:active,
.rs-btn-link.rs-btn-cyan.rs-btn-loading:hover:active {
  color: #0087a6;
  background-color: transparent;
}
.rs-btn-link.rs-btn-cyan,
.rs-btn-link.rs-btn-cyan:active,
.rs-btn-link.rs-btn-cyan.rs-btn-active {
  background-color: transparent;
}
.rs-btn-link.rs-btn-cyan,
.rs-btn-link.rs-btn-cyan:hover,
.rs-btn-link.rs-btn-cyan:focus,
.rs-btn-link.rs-btn-cyan:active {
  border-color: transparent;
}
.rs-btn-link.rs-btn-cyan:hover,
.rs-btn-link.rs-btn-cyan:focus {
  color: #00728f;
  text-decoration: underline;
  background-color: transparent;
}
.rs-btn-link.rs-btn-cyan:active,
.rs-btn-link.rs-btn-cyan.rs-btn-active {
  color: #005870;
}
.rs-btn-link.rs-btn-cyan:active:hover,
.rs-btn-link.rs-btn-cyan.rs-btn-active:hover,
.rs-btn-link.rs-btn-cyan:active:focus,
.rs-btn-link.rs-btn-cyan.rs-btn-active:focus,
.rs-btn-link.rs-btn-cyan:active.focus,
.rs-btn-link.rs-btn-cyan.rs-btn-active.focus {
  color: #005870;
}
.rs-btn-link.rs-btn-cyan.rs-btn-disabled:hover,
.rs-btn-link.rs-btn-cyan.rs-btn-disabled:focus {
  color: #0087a6;
  text-decoration: none;
}
.rs-btn-link.rs-btn-blue {
  color: #045dbd;
  font-weight: normal;
  border-radius: 0;
}
.rs-btn-link.rs-btn-blue.rs-btn-loading:hover,
.rs-btn-link.rs-btn-blue.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-link.rs-btn-blue.rs-btn-loading:focus,
.rs-btn-link.rs-btn-blue.rs-btn-loading:active,
.rs-btn-link.rs-btn-blue.rs-btn-loading:hover:active {
  color: #045dbd;
  background-color: transparent;
}
.rs-btn-link.rs-btn-blue,
.rs-btn-link.rs-btn-blue:active,
.rs-btn-link.rs-btn-blue.rs-btn-active {
  background-color: transparent;
}
.rs-btn-link.rs-btn-blue,
.rs-btn-link.rs-btn-blue:hover,
.rs-btn-link.rs-btn-blue:focus,
.rs-btn-link.rs-btn-blue:active {
  border-color: transparent;
}
.rs-btn-link.rs-btn-blue:hover,
.rs-btn-link.rs-btn-blue:focus {
  color: #004da6;
  text-decoration: underline;
  background-color: transparent;
}
.rs-btn-link.rs-btn-blue:active,
.rs-btn-link.rs-btn-blue.rs-btn-active {
  color: #003d87;
}
.rs-btn-link.rs-btn-blue:active:hover,
.rs-btn-link.rs-btn-blue.rs-btn-active:hover,
.rs-btn-link.rs-btn-blue:active:focus,
.rs-btn-link.rs-btn-blue.rs-btn-active:focus,
.rs-btn-link.rs-btn-blue:active.focus,
.rs-btn-link.rs-btn-blue.rs-btn-active.focus {
  color: #003d87;
}
.rs-btn-link.rs-btn-blue.rs-btn-disabled:hover,
.rs-btn-link.rs-btn-blue.rs-btn-disabled:focus {
  color: #045dbd;
  text-decoration: none;
}
.rs-btn-link.rs-btn-violet {
  color: #490c99;
  font-weight: normal;
  border-radius: 0;
}
.rs-btn-link.rs-btn-violet.rs-btn-loading:hover,
.rs-btn-link.rs-btn-violet.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-link.rs-btn-violet.rs-btn-loading:focus,
.rs-btn-link.rs-btn-violet.rs-btn-loading:active,
.rs-btn-link.rs-btn-violet.rs-btn-loading:hover:active {
  color: #490c99;
  background-color: transparent;
}
.rs-btn-link.rs-btn-violet,
.rs-btn-link.rs-btn-violet:active,
.rs-btn-link.rs-btn-violet.rs-btn-active {
  background-color: transparent;
}
.rs-btn-link.rs-btn-violet,
.rs-btn-link.rs-btn-violet:hover,
.rs-btn-link.rs-btn-violet:focus,
.rs-btn-link.rs-btn-violet:active {
  border-color: transparent;
}
.rs-btn-link.rs-btn-violet:hover,
.rs-btn-link.rs-btn-violet:focus {
  color: #41068a;
  text-decoration: underline;
  background-color: transparent;
}
.rs-btn-link.rs-btn-violet:active,
.rs-btn-link.rs-btn-violet.rs-btn-active {
  color: #370075;
}
.rs-btn-link.rs-btn-violet:active:hover,
.rs-btn-link.rs-btn-violet.rs-btn-active:hover,
.rs-btn-link.rs-btn-violet:active:focus,
.rs-btn-link.rs-btn-violet.rs-btn-active:focus,
.rs-btn-link.rs-btn-violet:active.focus,
.rs-btn-link.rs-btn-violet.rs-btn-active.focus {
  color: #370075;
}
.rs-btn-link.rs-btn-violet.rs-btn-disabled:hover,
.rs-btn-link.rs-btn-violet.rs-btn-disabled:focus {
  color: #490c99;
  text-decoration: none;
}
.rs-btn-subtle {
  transition: color 0.2s linear, background-color 0.3s linear;
  color: #8e8e93;
  background-color: transparent;
}
.rs-btn-subtle.rs-btn-loading:hover,
.rs-btn-subtle.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-subtle.rs-btn-loading:focus,
.rs-btn-subtle.rs-btn-loading:active,
.rs-btn-subtle.rs-btn-loading:hover:active {
  color: #8e8e93;
  background-color: transparent;
}
.rs-btn-subtle:focus,
.rs-btn-subtle.rs-btn-focus,
.rs-btn-subtle:hover {
  color: #fff;
  background: #f7f7fa;
}
.rs-btn-subtle:active,
.rs-btn-subtle.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle {
  color: #fff;
  background-color: #b2b1f0;
  background-image: none;
}
.rs-btn-subtle:active:hover,
.rs-btn-subtle.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle:hover,
.rs-btn-subtle:active:focus,
.rs-btn-subtle.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle:focus,
.rs-btn-subtle:active.focus,
.rs-btn-subtle.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle.focus {
  color: #fff;
  background-color: #b2b1f0;
}
.rs-btn-subtle.rs-btn-disabled,
.rs-btn-subtle.rs-btn-disabled:hover,
.rs-btn-subtle.rs-btn-disabled:focus,
.rs-btn-subtle.rs-btn-disabled.focus,
.rs-btn-subtle.rs-btn-disabled:active,
.rs-btn-subtle.rs-btn-disabled:hover:active {
  color: #c5c6c7;
  background: none;
}
.rs-btn-subtle.rs-btn-loading:hover,
.rs-btn-subtle.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-subtle.rs-btn-loading:focus,
.rs-btn-subtle.rs-btn-loading:active,
.rs-btn-subtle.rs-btn-loading:hover:active {
  color: #8e8e93;
  background-color: transparent;
}
.rs-btn-subtle.rs-btn-disabled,
.rs-btn-subtle.rs-btn-disabled:hover,
.rs-btn-subtle.rs-btn-disabled:focus,
.rs-btn-subtle.rs-btn-disabled:active {
  opacity: 1;
}
.rs-btn-subtle:focus,
.rs-btn-subtle.rs-btn-focus,
.rs-btn-subtle:hover {
  color: #575757;
}
.rs-btn-subtle:active,
.rs-btn-subtle.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle {
  color: #272c36;
  background-color: #e5e5ea;
  background-image: none;
}
.rs-btn-subtle:active:hover,
.rs-btn-subtle.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle:hover,
.rs-btn-subtle:active:focus,
.rs-btn-subtle.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle:focus,
.rs-btn-subtle:active.focus,
.rs-btn-subtle.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle.focus {
  color: #272c36;
  background-color: #e5e5ea;
}
.rs-btn-subtle.rs-btn-red {
  color: #8e8e93;
  background-color: transparent;
}
.rs-btn-subtle.rs-btn-red.rs-btn-loading:hover,
.rs-btn-subtle.rs-btn-red.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-subtle.rs-btn-red.rs-btn-loading:focus,
.rs-btn-subtle.rs-btn-red.rs-btn-loading:active,
.rs-btn-subtle.rs-btn-red.rs-btn-loading:hover:active {
  color: #8e8e93;
  background-color: transparent;
}
.rs-btn-subtle.rs-btn-red:focus,
.rs-btn-subtle.rs-btn-red.rs-btn-focus,
.rs-btn-subtle.rs-btn-red:hover {
  color: #fff;
  background: #f44336;
}
.rs-btn-subtle.rs-btn-red:active,
.rs-btn-subtle.rs-btn-red.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-red {
  color: #fff;
  background-color: #eb3626;
  background-image: none;
}
.rs-btn-subtle.rs-btn-red:active:hover,
.rs-btn-subtle.rs-btn-red.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-red:hover,
.rs-btn-subtle.rs-btn-red:active:focus,
.rs-btn-subtle.rs-btn-red.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-red:focus,
.rs-btn-subtle.rs-btn-red:active.focus,
.rs-btn-subtle.rs-btn-red.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-red.focus {
  color: #fff;
  background-color: #eb3626;
}
.rs-btn-subtle.rs-btn-red.rs-btn-disabled,
.rs-btn-subtle.rs-btn-red.rs-btn-disabled:hover,
.rs-btn-subtle.rs-btn-red.rs-btn-disabled:focus,
.rs-btn-subtle.rs-btn-red.rs-btn-disabled.focus,
.rs-btn-subtle.rs-btn-red.rs-btn-disabled:active,
.rs-btn-subtle.rs-btn-red.rs-btn-disabled:hover:active {
  color: #c5c6c7;
  background: none;
}
.rs-btn-subtle.rs-btn-orange {
  color: #8e8e93;
  background-color: transparent;
}
.rs-btn-subtle.rs-btn-orange.rs-btn-loading:hover,
.rs-btn-subtle.rs-btn-orange.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-subtle.rs-btn-orange.rs-btn-loading:focus,
.rs-btn-subtle.rs-btn-orange.rs-btn-loading:active,
.rs-btn-subtle.rs-btn-orange.rs-btn-loading:hover:active {
  color: #8e8e93;
  background-color: transparent;
}
.rs-btn-subtle.rs-btn-orange:focus,
.rs-btn-subtle.rs-btn-orange.rs-btn-focus,
.rs-btn-subtle.rs-btn-orange:hover {
  color: #fff;
  background: #ff9800;
}
.rs-btn-subtle.rs-btn-orange:active,
.rs-btn-subtle.rs-btn-orange.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-orange {
  color: #fff;
  background-color: #f59700;
  background-image: none;
}
.rs-btn-subtle.rs-btn-orange:active:hover,
.rs-btn-subtle.rs-btn-orange.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-orange:hover,
.rs-btn-subtle.rs-btn-orange:active:focus,
.rs-btn-subtle.rs-btn-orange.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-orange:focus,
.rs-btn-subtle.rs-btn-orange:active.focus,
.rs-btn-subtle.rs-btn-orange.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-orange.focus {
  color: #fff;
  background-color: #f59700;
}
.rs-btn-subtle.rs-btn-orange.rs-btn-disabled,
.rs-btn-subtle.rs-btn-orange.rs-btn-disabled:hover,
.rs-btn-subtle.rs-btn-orange.rs-btn-disabled:focus,
.rs-btn-subtle.rs-btn-orange.rs-btn-disabled.focus,
.rs-btn-subtle.rs-btn-orange.rs-btn-disabled:active,
.rs-btn-subtle.rs-btn-orange.rs-btn-disabled:hover:active {
  color: #c5c6c7;
  background: none;
}
.rs-btn-subtle.rs-btn-yellow {
  color: #8e8e93;
  background-color: transparent;
}
.rs-btn-subtle.rs-btn-yellow.rs-btn-loading:hover,
.rs-btn-subtle.rs-btn-yellow.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-subtle.rs-btn-yellow.rs-btn-loading:focus,
.rs-btn-subtle.rs-btn-yellow.rs-btn-loading:active,
.rs-btn-subtle.rs-btn-yellow.rs-btn-loading:hover:active {
  color: #8e8e93;
  background-color: transparent;
}
.rs-btn-subtle.rs-btn-yellow:focus,
.rs-btn-subtle.rs-btn-yellow.rs-btn-focus,
.rs-btn-subtle.rs-btn-yellow:hover {
  color: #fff;
  background: #ffca28;
}
.rs-btn-subtle.rs-btn-yellow:active,
.rs-btn-subtle.rs-btn-yellow.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-yellow {
  color: #fff;
  background-color: #f5c31d;
  background-image: none;
}
.rs-btn-subtle.rs-btn-yellow:active:hover,
.rs-btn-subtle.rs-btn-yellow.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-yellow:hover,
.rs-btn-subtle.rs-btn-yellow:active:focus,
.rs-btn-subtle.rs-btn-yellow.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-yellow:focus,
.rs-btn-subtle.rs-btn-yellow:active.focus,
.rs-btn-subtle.rs-btn-yellow.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-yellow.focus {
  color: #fff;
  background-color: #f5c31d;
}
.rs-btn-subtle.rs-btn-yellow.rs-btn-disabled,
.rs-btn-subtle.rs-btn-yellow.rs-btn-disabled:hover,
.rs-btn-subtle.rs-btn-yellow.rs-btn-disabled:focus,
.rs-btn-subtle.rs-btn-yellow.rs-btn-disabled.focus,
.rs-btn-subtle.rs-btn-yellow.rs-btn-disabled:active,
.rs-btn-subtle.rs-btn-yellow.rs-btn-disabled:hover:active {
  color: #c5c6c7;
  background: none;
}
.rs-btn-subtle.rs-btn-green {
  color: #8e8e93;
  background-color: transparent;
}
.rs-btn-subtle.rs-btn-green.rs-btn-loading:hover,
.rs-btn-subtle.rs-btn-green.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-subtle.rs-btn-green.rs-btn-loading:focus,
.rs-btn-subtle.rs-btn-green.rs-btn-loading:active,
.rs-btn-subtle.rs-btn-green.rs-btn-loading:hover:active {
  color: #8e8e93;
  background-color: transparent;
}
.rs-btn-subtle.rs-btn-green:focus,
.rs-btn-subtle.rs-btn-green.rs-btn-focus,
.rs-btn-subtle.rs-btn-green:hover {
  color: #fff;
  background: #4caf50;
}
.rs-btn-subtle.rs-btn-green:active,
.rs-btn-subtle.rs-btn-green.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-green {
  color: #fff;
  background-color: #37ab3c;
  background-image: none;
}
.rs-btn-subtle.rs-btn-green:active:hover,
.rs-btn-subtle.rs-btn-green.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-green:hover,
.rs-btn-subtle.rs-btn-green:active:focus,
.rs-btn-subtle.rs-btn-green.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-green:focus,
.rs-btn-subtle.rs-btn-green:active.focus,
.rs-btn-subtle.rs-btn-green.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-green.focus {
  color: #fff;
  background-color: #37ab3c;
}
.rs-btn-subtle.rs-btn-green.rs-btn-disabled,
.rs-btn-subtle.rs-btn-green.rs-btn-disabled:hover,
.rs-btn-subtle.rs-btn-green.rs-btn-disabled:focus,
.rs-btn-subtle.rs-btn-green.rs-btn-disabled.focus,
.rs-btn-subtle.rs-btn-green.rs-btn-disabled:active,
.rs-btn-subtle.rs-btn-green.rs-btn-disabled:hover:active {
  color: #c5c6c7;
  background: none;
}
.rs-btn-subtle.rs-btn-cyan {
  color: #8e8e93;
  background-color: transparent;
}
.rs-btn-subtle.rs-btn-cyan.rs-btn-loading:hover,
.rs-btn-subtle.rs-btn-cyan.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-subtle.rs-btn-cyan.rs-btn-loading:focus,
.rs-btn-subtle.rs-btn-cyan.rs-btn-loading:active,
.rs-btn-subtle.rs-btn-cyan.rs-btn-loading:hover:active {
  color: #8e8e93;
  background-color: transparent;
}
.rs-btn-subtle.rs-btn-cyan:focus,
.rs-btn-subtle.rs-btn-cyan.rs-btn-focus,
.rs-btn-subtle.rs-btn-cyan:hover {
  color: #fff;
  background: #00bcd4;
}
.rs-btn-subtle.rs-btn-cyan:active,
.rs-btn-subtle.rs-btn-cyan.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-cyan {
  color: #fff;
  background-color: #00b1cc;
  background-image: none;
}
.rs-btn-subtle.rs-btn-cyan:active:hover,
.rs-btn-subtle.rs-btn-cyan.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-cyan:hover,
.rs-btn-subtle.rs-btn-cyan:active:focus,
.rs-btn-subtle.rs-btn-cyan.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-cyan:focus,
.rs-btn-subtle.rs-btn-cyan:active.focus,
.rs-btn-subtle.rs-btn-cyan.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-cyan.focus {
  color: #fff;
  background-color: #00b1cc;
}
.rs-btn-subtle.rs-btn-cyan.rs-btn-disabled,
.rs-btn-subtle.rs-btn-cyan.rs-btn-disabled:hover,
.rs-btn-subtle.rs-btn-cyan.rs-btn-disabled:focus,
.rs-btn-subtle.rs-btn-cyan.rs-btn-disabled.focus,
.rs-btn-subtle.rs-btn-cyan.rs-btn-disabled:active,
.rs-btn-subtle.rs-btn-cyan.rs-btn-disabled:hover:active {
  color: #c5c6c7;
  background: none;
}
.rs-btn-subtle.rs-btn-blue {
  color: #8e8e93;
  background-color: transparent;
}
.rs-btn-subtle.rs-btn-blue.rs-btn-loading:hover,
.rs-btn-subtle.rs-btn-blue.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-subtle.rs-btn-blue.rs-btn-loading:focus,
.rs-btn-subtle.rs-btn-blue.rs-btn-loading:active,
.rs-btn-subtle.rs-btn-blue.rs-btn-loading:hover:active {
  color: #8e8e93;
  background-color: transparent;
}
.rs-btn-subtle.rs-btn-blue:focus,
.rs-btn-subtle.rs-btn-blue.rs-btn-focus,
.rs-btn-subtle.rs-btn-blue:hover {
  color: #fff;
  background: #2196f3;
}
.rs-btn-subtle.rs-btn-blue:active,
.rs-btn-subtle.rs-btn-blue.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-blue {
  color: #fff;
  background-color: #1787e8;
  background-image: none;
}
.rs-btn-subtle.rs-btn-blue:active:hover,
.rs-btn-subtle.rs-btn-blue.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-blue:hover,
.rs-btn-subtle.rs-btn-blue:active:focus,
.rs-btn-subtle.rs-btn-blue.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-blue:focus,
.rs-btn-subtle.rs-btn-blue:active.focus,
.rs-btn-subtle.rs-btn-blue.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-blue.focus {
  color: #fff;
  background-color: #1787e8;
}
.rs-btn-subtle.rs-btn-blue.rs-btn-disabled,
.rs-btn-subtle.rs-btn-blue.rs-btn-disabled:hover,
.rs-btn-subtle.rs-btn-blue.rs-btn-disabled:focus,
.rs-btn-subtle.rs-btn-blue.rs-btn-disabled.focus,
.rs-btn-subtle.rs-btn-blue.rs-btn-disabled:active,
.rs-btn-subtle.rs-btn-blue.rs-btn-disabled:hover:active {
  color: #c5c6c7;
  background: none;
}
.rs-btn-subtle.rs-btn-violet {
  color: #8e8e93;
  background-color: transparent;
}
.rs-btn-subtle.rs-btn-violet.rs-btn-loading:hover,
.rs-btn-subtle.rs-btn-violet.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-subtle.rs-btn-violet.rs-btn-loading:focus,
.rs-btn-subtle.rs-btn-violet.rs-btn-loading:active,
.rs-btn-subtle.rs-btn-violet.rs-btn-loading:hover:active {
  color: #8e8e93;
  background-color: transparent;
}
.rs-btn-subtle.rs-btn-violet:focus,
.rs-btn-subtle.rs-btn-violet.rs-btn-focus,
.rs-btn-subtle.rs-btn-violet:hover {
  color: #fff;
  background: #673ab7;
}
.rs-btn-subtle.rs-btn-violet:active,
.rs-btn-subtle.rs-btn-violet.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-violet {
  color: #fff;
  background-color: #5f2bb3;
  background-image: none;
}
.rs-btn-subtle.rs-btn-violet:active:hover,
.rs-btn-subtle.rs-btn-violet.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-violet:hover,
.rs-btn-subtle.rs-btn-violet:active:focus,
.rs-btn-subtle.rs-btn-violet.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-violet:focus,
.rs-btn-subtle.rs-btn-violet:active.focus,
.rs-btn-subtle.rs-btn-violet.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-violet.focus {
  color: #fff;
  background-color: #5f2bb3;
}
.rs-btn-subtle.rs-btn-violet.rs-btn-disabled,
.rs-btn-subtle.rs-btn-violet.rs-btn-disabled:hover,
.rs-btn-subtle.rs-btn-violet.rs-btn-disabled:focus,
.rs-btn-subtle.rs-btn-violet.rs-btn-disabled.focus,
.rs-btn-subtle.rs-btn-violet.rs-btn-disabled:active,
.rs-btn-subtle.rs-btn-violet.rs-btn-disabled:hover:active {
  color: #c5c6c7;
  background: none;
}
.rs-btn-ghost {
  transition: color 0.2s linear, background-color 0.3s linear;
  color: #bd0003;
  border: 1px solid #bd0003;
  background-color: transparent;
}
.rs-btn-ghost.rs-btn-loading:hover,
.rs-btn-ghost.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-ghost.rs-btn-loading:focus,
.rs-btn-ghost.rs-btn-loading:active,
.rs-btn-ghost.rs-btn-loading:hover:active {
  color: #bd0003;
  background-color: transparent;
}
.rs-btn-ghost:focus,
.rs-btn-ghost.rs-btn-focus {
  color: #a60000;
  border-color: #a60000;
}
.rs-btn-ghost:hover {
  color: #a60000;
  border-color: #a60000;
}
.rs-btn-ghost:active,
.rs-btn-ghost.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-ghost {
  color: #870200;
  border-color: #870200;
  background-image: none;
}
.rs-btn-ghost:active:hover,
.rs-btn-ghost.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-ghost:hover,
.rs-btn-ghost:active:focus,
.rs-btn-ghost.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-ghost:focus,
.rs-btn-ghost:active.rs-btn-focus,
.rs-btn-ghost.rs-btn-active.rs-btn-focus,
.rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-focus {
  color: #870200;
  border-color: #870200;
}
.rs-btn-ghost.rs-btn-disabled:hover,
.rs-btn-ghost.rs-btn-disabled:focus,
.rs-btn-ghost.rs-btn-disabled.focus {
  background: none;
}
.rs-btn-ghost.rs-btn-red {
  color: #bf2008;
  border: 1px solid #bf2008;
  background-color: transparent;
}
.rs-btn-ghost.rs-btn-red.rs-btn-loading:hover,
.rs-btn-ghost.rs-btn-red.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-ghost.rs-btn-red.rs-btn-loading:focus,
.rs-btn-ghost.rs-btn-red.rs-btn-loading:active,
.rs-btn-ghost.rs-btn-red.rs-btn-loading:hover:active {
  color: #bf2008;
  background-color: transparent;
}
.rs-btn-ghost.rs-btn-red:focus,
.rs-btn-ghost.rs-btn-red.rs-btn-focus {
  color: #a81b02;
  border-color: #a81b02;
}
.rs-btn-ghost.rs-btn-red:hover {
  color: #a81b02;
  border-color: #a81b02;
}
.rs-btn-ghost.rs-btn-red:active,
.rs-btn-ghost.rs-btn-red.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-red {
  color: #8a1700;
  border-color: #8a1700;
  background-image: none;
}
.rs-btn-ghost.rs-btn-red:active:hover,
.rs-btn-ghost.rs-btn-red.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-red:hover,
.rs-btn-ghost.rs-btn-red:active:focus,
.rs-btn-ghost.rs-btn-red.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-red:focus,
.rs-btn-ghost.rs-btn-red:active.rs-btn-focus,
.rs-btn-ghost.rs-btn-red.rs-btn-active.rs-btn-focus,
.rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-red.rs-btn-focus {
  color: #8a1700;
  border-color: #8a1700;
}
.rs-btn-ghost.rs-btn-red.rs-btn-disabled:hover,
.rs-btn-ghost.rs-btn-red.rs-btn-disabled:focus,
.rs-btn-ghost.rs-btn-red.rs-btn-disabled.focus {
  background: none;
}
.rs-btn-ghost.rs-btn-orange {
  color: #c98600;
  border: 1px solid #c98600;
  background-color: transparent;
}
.rs-btn-ghost.rs-btn-orange.rs-btn-loading:hover,
.rs-btn-ghost.rs-btn-orange.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-ghost.rs-btn-orange.rs-btn-loading:focus,
.rs-btn-ghost.rs-btn-orange.rs-btn-loading:active,
.rs-btn-ghost.rs-btn-orange.rs-btn-loading:hover:active {
  color: #c98600;
  background-color: transparent;
}
.rs-btn-ghost.rs-btn-orange:focus,
.rs-btn-ghost.rs-btn-orange.rs-btn-focus {
  color: #b37a00;
  border-color: #b37a00;
}
.rs-btn-ghost.rs-btn-orange:hover {
  color: #b37a00;
  border-color: #b37a00;
}
.rs-btn-ghost.rs-btn-orange:active,
.rs-btn-ghost.rs-btn-orange.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-orange {
  color: #946800;
  border-color: #946800;
  background-image: none;
}
.rs-btn-ghost.rs-btn-orange:active:hover,
.rs-btn-ghost.rs-btn-orange.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-orange:hover,
.rs-btn-ghost.rs-btn-orange:active:focus,
.rs-btn-ghost.rs-btn-orange.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-orange:focus,
.rs-btn-ghost.rs-btn-orange:active.rs-btn-focus,
.rs-btn-ghost.rs-btn-orange.rs-btn-active.rs-btn-focus,
.rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-orange.rs-btn-focus {
  color: #946800;
  border-color: #946800;
}
.rs-btn-ghost.rs-btn-orange.rs-btn-disabled:hover,
.rs-btn-ghost.rs-btn-orange.rs-btn-disabled:focus,
.rs-btn-ghost.rs-btn-orange.rs-btn-disabled.focus {
  background: none;
}
.rs-btn-ghost.rs-btn-yellow {
  color: #c9a608;
  border: 1px solid #c9a608;
  background-color: transparent;
}
.rs-btn-ghost.rs-btn-yellow.rs-btn-loading:hover,
.rs-btn-ghost.rs-btn-yellow.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-ghost.rs-btn-yellow.rs-btn-loading:focus,
.rs-btn-ghost.rs-btn-yellow.rs-btn-loading:active,
.rs-btn-ghost.rs-btn-yellow.rs-btn-loading:hover:active {
  color: #c9a608;
  background-color: transparent;
}
.rs-btn-ghost.rs-btn-yellow:focus,
.rs-btn-ghost.rs-btn-yellow.rs-btn-focus {
  color: #b39504;
  border-color: #b39504;
}
.rs-btn-ghost.rs-btn-yellow:hover {
  color: #b39504;
  border-color: #b39504;
}
.rs-btn-ghost.rs-btn-yellow:active,
.rs-btn-ghost.rs-btn-yellow.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-yellow {
  color: #947e00;
  border-color: #947e00;
  background-image: none;
}
.rs-btn-ghost.rs-btn-yellow:active:hover,
.rs-btn-ghost.rs-btn-yellow.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-yellow:hover,
.rs-btn-ghost.rs-btn-yellow:active:focus,
.rs-btn-ghost.rs-btn-yellow.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-yellow:focus,
.rs-btn-ghost.rs-btn-yellow:active.rs-btn-focus,
.rs-btn-ghost.rs-btn-yellow.rs-btn-active.rs-btn-focus,
.rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-yellow.rs-btn-focus {
  color: #947e00;
  border-color: #947e00;
}
.rs-btn-ghost.rs-btn-yellow.rs-btn-disabled:hover,
.rs-btn-ghost.rs-btn-yellow.rs-btn-disabled:focus,
.rs-btn-ghost.rs-btn-yellow.rs-btn-disabled.focus {
  background: none;
}
.rs-btn-ghost.rs-btn-green {
  color: #10911d;
  border: 1px solid #10911d;
  background-color: transparent;
}
.rs-btn-ghost.rs-btn-green.rs-btn-loading:hover,
.rs-btn-ghost.rs-btn-green.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-ghost.rs-btn-green.rs-btn-loading:focus,
.rs-btn-ghost.rs-btn-green.rs-btn-loading:active,
.rs-btn-ghost.rs-btn-green.rs-btn-loading:hover:active {
  color: #10911d;
  background-color: transparent;
}
.rs-btn-ghost.rs-btn-green:focus,
.rs-btn-ghost.rs-btn-green.rs-btn-focus {
  color: #088216;
  border-color: #088216;
}
.rs-btn-ghost.rs-btn-green:hover {
  color: #088216;
  border-color: #088216;
}
.rs-btn-ghost.rs-btn-green:active,
.rs-btn-ghost.rs-btn-green.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-green {
  color: #016e10;
  border-color: #016e10;
  background-image: none;
}
.rs-btn-ghost.rs-btn-green:active:hover,
.rs-btn-ghost.rs-btn-green.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-green:hover,
.rs-btn-ghost.rs-btn-green:active:focus,
.rs-btn-ghost.rs-btn-green.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-green:focus,
.rs-btn-ghost.rs-btn-green:active.rs-btn-focus,
.rs-btn-ghost.rs-btn-green.rs-btn-active.rs-btn-focus,
.rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-green.rs-btn-focus {
  color: #016e10;
  border-color: #016e10;
}
.rs-btn-ghost.rs-btn-green.rs-btn-disabled:hover,
.rs-btn-ghost.rs-btn-green.rs-btn-disabled:focus,
.rs-btn-ghost.rs-btn-green.rs-btn-disabled.focus {
  background: none;
}
.rs-btn-ghost.rs-btn-cyan {
  color: #0087a6;
  border: 1px solid #0087a6;
  background-color: transparent;
}
.rs-btn-ghost.rs-btn-cyan.rs-btn-loading:hover,
.rs-btn-ghost.rs-btn-cyan.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-ghost.rs-btn-cyan.rs-btn-loading:focus,
.rs-btn-ghost.rs-btn-cyan.rs-btn-loading:active,
.rs-btn-ghost.rs-btn-cyan.rs-btn-loading:hover:active {
  color: #0087a6;
  background-color: transparent;
}
.rs-btn-ghost.rs-btn-cyan:focus,
.rs-btn-ghost.rs-btn-cyan.rs-btn-focus {
  color: #00728f;
  border-color: #00728f;
}
.rs-btn-ghost.rs-btn-cyan:hover {
  color: #00728f;
  border-color: #00728f;
}
.rs-btn-ghost.rs-btn-cyan:active,
.rs-btn-ghost.rs-btn-cyan.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-cyan {
  color: #005870;
  border-color: #005870;
  background-image: none;
}
.rs-btn-ghost.rs-btn-cyan:active:hover,
.rs-btn-ghost.rs-btn-cyan.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-cyan:hover,
.rs-btn-ghost.rs-btn-cyan:active:focus,
.rs-btn-ghost.rs-btn-cyan.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-cyan:focus,
.rs-btn-ghost.rs-btn-cyan:active.rs-btn-focus,
.rs-btn-ghost.rs-btn-cyan.rs-btn-active.rs-btn-focus,
.rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-cyan.rs-btn-focus {
  color: #005870;
  border-color: #005870;
}
.rs-btn-ghost.rs-btn-cyan.rs-btn-disabled:hover,
.rs-btn-ghost.rs-btn-cyan.rs-btn-disabled:focus,
.rs-btn-ghost.rs-btn-cyan.rs-btn-disabled.focus {
  background: none;
}
.rs-btn-ghost.rs-btn-blue {
  color: #045dbd;
  border: 1px solid #045dbd;
  background-color: transparent;
}
.rs-btn-ghost.rs-btn-blue.rs-btn-loading:hover,
.rs-btn-ghost.rs-btn-blue.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-ghost.rs-btn-blue.rs-btn-loading:focus,
.rs-btn-ghost.rs-btn-blue.rs-btn-loading:active,
.rs-btn-ghost.rs-btn-blue.rs-btn-loading:hover:active {
  color: #045dbd;
  background-color: transparent;
}
.rs-btn-ghost.rs-btn-blue:focus,
.rs-btn-ghost.rs-btn-blue.rs-btn-focus {
  color: #004da6;
  border-color: #004da6;
}
.rs-btn-ghost.rs-btn-blue:hover {
  color: #004da6;
  border-color: #004da6;
}
.rs-btn-ghost.rs-btn-blue:active,
.rs-btn-ghost.rs-btn-blue.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-blue {
  color: #003d87;
  border-color: #003d87;
  background-image: none;
}
.rs-btn-ghost.rs-btn-blue:active:hover,
.rs-btn-ghost.rs-btn-blue.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-blue:hover,
.rs-btn-ghost.rs-btn-blue:active:focus,
.rs-btn-ghost.rs-btn-blue.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-blue:focus,
.rs-btn-ghost.rs-btn-blue:active.rs-btn-focus,
.rs-btn-ghost.rs-btn-blue.rs-btn-active.rs-btn-focus,
.rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-blue.rs-btn-focus {
  color: #003d87;
  border-color: #003d87;
}
.rs-btn-ghost.rs-btn-blue.rs-btn-disabled:hover,
.rs-btn-ghost.rs-btn-blue.rs-btn-disabled:focus,
.rs-btn-ghost.rs-btn-blue.rs-btn-disabled.focus {
  background: none;
}
.rs-btn-ghost.rs-btn-violet {
  color: #490c99;
  border: 1px solid #490c99;
  background-color: transparent;
}
.rs-btn-ghost.rs-btn-violet.rs-btn-loading:hover,
.rs-btn-ghost.rs-btn-violet.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-ghost.rs-btn-violet.rs-btn-loading:focus,
.rs-btn-ghost.rs-btn-violet.rs-btn-loading:active,
.rs-btn-ghost.rs-btn-violet.rs-btn-loading:hover:active {
  color: #490c99;
  background-color: transparent;
}
.rs-btn-ghost.rs-btn-violet:focus,
.rs-btn-ghost.rs-btn-violet.rs-btn-focus {
  color: #41068a;
  border-color: #41068a;
}
.rs-btn-ghost.rs-btn-violet:hover {
  color: #41068a;
  border-color: #41068a;
}
.rs-btn-ghost.rs-btn-violet:active,
.rs-btn-ghost.rs-btn-violet.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-violet {
  color: #370075;
  border-color: #370075;
  background-image: none;
}
.rs-btn-ghost.rs-btn-violet:active:hover,
.rs-btn-ghost.rs-btn-violet.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-violet:hover,
.rs-btn-ghost.rs-btn-violet:active:focus,
.rs-btn-ghost.rs-btn-violet.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-violet:focus,
.rs-btn-ghost.rs-btn-violet:active.rs-btn-focus,
.rs-btn-ghost.rs-btn-violet.rs-btn-active.rs-btn-focus,
.rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-violet.rs-btn-focus {
  color: #370075;
  border-color: #370075;
}
.rs-btn-ghost.rs-btn-violet.rs-btn-disabled:hover,
.rs-btn-ghost.rs-btn-violet.rs-btn-disabled:focus,
.rs-btn-ghost.rs-btn-violet.rs-btn-disabled.focus {
  background: none;
}
.rs-btn-lg {
  /* stylelint-disable */
  padding: 10px 16px;
  font-size: 16px;
  line-height: 1.375;
  border-radius: 4px;
  /* stylelint-enable */
}
.rs-btn-lg.rs-btn-ghost {
  padding-top: 9px;
  padding-bottom: 9px;
}
.rs-btn-lg.rs-btn-icon {
  padding-left: 26px;
  height: 42px;
  position: relative;
}
.rs-btn-ghost.rs-btn-lg.rs-btn-icon {
  padding-left: 24px;
}
.rs-btn-lg.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left {
  padding-left: 52px;
}
.rs-btn-lg.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right {
  padding-left: 12px;
  padding-right: 52px;
}
.rs-btn-lg.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon {
  left: auto;
  right: 0;
}
.rs-btn-lg.rs-btn-icon > .rs-icon {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 42px;
  height: 42px;
  padding: 10px 0;
  font-size: 16px;
  line-height: 1.375;
}
.rs-btn-ghost.rs-btn-lg.rs-btn-icon > .rs-icon {
  width: 40px;
  height: 40px;
  padding: 9px 0;
}
.rs-btn-lg.rs-btn-icon > .rs-icon > svg {
  height: 16px;
  vertical-align: middle;
}
.rs-btn-lg.rs-btn-icon-circle {
  width: 42px;
  padding: 10px;
}
.rs-btn-sm {
  /* stylelint-disable */
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  /* stylelint-enable */
}
.rs-btn-sm.rs-btn-ghost {
  padding-top: 4px;
  padding-bottom: 4px;
}
.rs-btn-sm.rs-btn-icon {
  padding-left: 20px;
  height: 30px;
  position: relative;
}
.rs-btn-ghost.rs-btn-sm.rs-btn-icon {
  padding-left: 18px;
}
.rs-btn-sm.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left {
  padding-left: 35px;
}
.rs-btn-sm.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right {
  padding-left: 12px;
  padding-right: 35px;
}
.rs-btn-sm.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon {
  left: auto;
  right: 0;
}
.rs-btn-sm.rs-btn-icon > .rs-icon {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 30px;
  height: 30px;
  padding: 5px 0;
  font-size: 14px;
  line-height: 1.42857143;
}
.rs-btn-ghost.rs-btn-sm.rs-btn-icon > .rs-icon {
  width: 28px;
  height: 28px;
  padding: 4px 0;
}
.rs-btn-sm.rs-btn-icon > .rs-icon > svg {
  height: 14px;
  vertical-align: middle;
}
.rs-btn-sm.rs-btn-icon-circle {
  width: 30px;
  padding: 5px;
}
.rs-btn-xs {
  /* stylelint-disable */
  padding: 2px 8px;
  font-size: 12px;
  line-height: 1.66666667;
  border-radius: 4px;
  /* stylelint-enable */
}
.rs-btn-xs.rs-btn-ghost {
  padding-top: 1px;
  padding-bottom: 1px;
}
.rs-btn-xs.rs-btn-icon {
  padding-left: 16px;
  height: 24px;
  position: relative;
}
.rs-btn-ghost.rs-btn-xs.rs-btn-icon {
  padding-left: 14px;
}
.rs-btn-xs.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left {
  padding-left: 26px;
}
.rs-btn-xs.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right {
  padding-left: 12px;
  padding-right: 26px;
}
.rs-btn-xs.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon {
  left: auto;
  right: 0;
}
.rs-btn-xs.rs-btn-icon > .rs-icon {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 24px;
  height: 24px;
  padding: 2px 0;
  font-size: 12px;
  line-height: 1.66666667;
}
.rs-btn-ghost.rs-btn-xs.rs-btn-icon > .rs-icon {
  width: 22px;
  height: 22px;
  padding: 1px 0;
}
.rs-btn-xs.rs-btn-icon > .rs-icon > svg {
  height: 12px;
  vertical-align: middle;
}
.rs-btn-xs.rs-btn-icon-circle {
  width: 24px;
  padding: 2px;
}
.rs-btn-block {
  display: block;
  width: 100%;
}
.rs-btn-block + .rs-btn-block {
  margin-top: 5px;
}
.rs-checkbox {
  position: relative;
  display: block;
}
.rs-checkbox-disabled label {
  cursor: not-allowed;
}
.rs-checkbox label {
  line-height: 1.57142857;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}
.rs-checkbox .rs-checkbox-inner::before {
  border-color: #00000073;
}
.rs-checkbox.rs-checkbox-disabled label {
  cursor: not-allowed;
}
.rs-checkbox-disabled > .rs-checkbox-checker > label {
  color: #c5c6c7;
}
.rs-checkbox-inline {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  margin-top: 0;
  margin-left: 10px;
}
.rs-checkbox-checker {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 42px;
  min-height: 42px;
  line-height: 1;
  position: relative;
}
/* rtl:begin:ignore */
.rs-checkbox-wrapper::before,
.rs-checkbox-wrapper::after,
.rs-checkbox-wrapper .rs-checkbox-inner::before,
.rs-checkbox-wrapper .rs-checkbox-inner::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  display: block;
}
.rs-checkbox-wrapper .rs-checkbox-inner::after {
  opacity: 0;
  transform: rotate(45deg) scale(0);
  transition: opacity 0.2s ease-in, transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}
.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::after,
.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::after {
  border: solid #000000;
  width: 6px;
  height: 9px;
  margin-top: 2px;
  margin-left: 5px;
  opacity: 1;
}
.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::after {
  border-width: 0 2px 2px 0;
  transform: rotate(45deg) scale(1);
}
.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::after {
  border-width: 0 0 2px;
  transform: rotate(0deg) scale(1);
  width: 10px;
  margin-top: 0;
  margin-left: 3px;
}
/* rtl:end:ignore */
/* stylelint-disable-next-line */
.rs-checkbox-wrapper {
  position: absolute;
  width: 22px;
  height: 22px;
  display: inline-block;
  left: 10px;
  top: 10px;
}
.rs-checkbox-wrapper [type='checkbox'] {
  width: 0;
  height: 0;
  opacity: 0;
}
.rs-checkbox-wrapper::before,
.rs-checkbox-wrapper .rs-checkbox-inner::before,
.rs-checkbox-wrapper .rs-checkbox-inner::after {
  width: 22px;
  height: 22px;
}
.rs-checkbox-wrapper::before {
  border: 1px solid #00000073;
  background-color: transparent;
  border-radius: 3px;
  transform: scale(1);
  opacity: 0.7;
  visibility: hidden;
  transition: transform 0.2s linear, opacity 0.2s linear;
}
.rs-checkbox-checked .rs-checkbox-wrapper::before {
  transform: scale(1.5);
  opacity: 0;
  visibility: visible;
}
.rs-checkbox-wrapper::after {
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
}
.rs-checkbox-wrapper .rs-checkbox-inner::before {
  border: 1px solid #00000073;
  background-color: transparent;
  border-radius: 3px;
  transition: background 0.2s linear, border 0.2s linear, box-shadow 0.2s linear;
}
.rs-checkbox-checker:hover .rs-checkbox-wrapper .rs-checkbox-inner::before {
  border-color: #00000073;
}
.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before,
.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
  border-color: #00000073;
  background-color: #00000073;
}
.rs-checkbox-disabled:not(.rs-checkbox-checked):not(.rs-checkbox-indeterminate) .rs-checkbox-wrapper .rs-checkbox-inner::before {
  border-color: #f7f7fa;
  background-color: #f7f7fa;
}
.rs-checkbox-disabled.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before,
.rs-checkbox-disabled.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
  opacity: 0.3;
}
.rs-checkbox-wrapper [type='checkbox']:focus ~ .rs-checkbox-inner::before {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.4);
}
:hover.rs-checkbox-wrapper [type='checkbox']:focus ~ .rs-checkbox-inner::before {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.4);
}
.rs-checkbox-checked .rs-checkbox-wrapper [type='checkbox']:focus ~ .rs-checkbox-inner::before {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.4);
}
.rs-checkbox-group-inline {
  margin-left: -10px;
}
.rs-dropdown-toggle-caret {
  display: inline-block;
  margin-left: 2px;
  position: absolute;
  top: 8px;
  right: 12px;
  font-weight: normal;
}
.rs-dropdown-toggle-caret::before {
  font-family: 'rsuite-icon-font' !important;
}
.rs-dropdown-placement-left-end .rs-dropdown-toggle-caret::before,
.rs-dropdown-placement-left-start .rs-dropdown-toggle-caret::before {
  content: '\ea0a' /* rtl: "\ea0c" */;
}
.rs-dropdown-placement-right-end .rs-dropdown-toggle-caret::before,
.rs-dropdown-placement-right-start .rs-dropdown-toggle-caret::before {
  content: '\ea0c' /* rtl: "\ea0a" */;
}
.rs-dropdown-placement-top-start .rs-dropdown-toggle-caret::before,
.rs-dropdown-placement-top-end .rs-dropdown-toggle-caret::before {
  content: '\ea0e';
}
.rs-dropdown-placement-bottom-start .rs-dropdown-toggle-caret::before,
.rs-dropdown-placement-bottom-end .rs-dropdown-toggle-caret::before {
  content: '\ea08';
}
.rs-dropdown {
  position: relative;
  display: inline-block;
  font-size: 0;
  vertical-align: middle;
}
.rs-dropdown .rs-btn > .rs-icon {
  margin-right: 6px;
}
.rs-dropdown .rs-btn > .rs-dropdown-toggle-caret {
  margin-left: 4px;
}
.rs-dropdown .rs-dropdown-menu {
  position: absolute;
  z-index: 6;
  display: none;
  float: left;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
}
.rs-dropdown-disabled .rs-dropdown-toggle.rs-btn {
  cursor: not-allowed !important;
}
.rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-subtle,
.rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-subtle:focus,
.rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-subtle:active {
  color: #c5c6c7;
  background: none;
}
.rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-primary,
.rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-primary:focus,
.rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-primary:active {
  opacity: 0.3;
  background-color: #D40511;
}
.rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-link,
.rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-link:focus,
.rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-link:active {
  opacity: 0.3;
  text-decoration: none;
}
.rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-ghost,
.rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-ghost:focus,
.rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-ghost:active {
  opacity: 0.3;
}
.rs-dropdown-disabled .rs-dropdown-toggle.rs-btn .rs-ripple-pond {
  display: none !important;
}
.rs-popover-content .rs-dropdown-item-submenu:not(.rs-dropdown-item-open) > .rs-dropdown-item-content > .rs-dropdown-menu {
  display: none;
}
.rs-dropdown-toggle.rs-btn-lg {
  padding-right: 36px !important;
}
.rs-dropdown-toggle.rs-btn-lg .rs-dropdown-toggle-caret {
  top: 10px;
  right: 16px;
}
.rs-dropdown-toggle.rs-btn-sm {
  padding-right: 30px !important;
}
.rs-dropdown-toggle.rs-btn-sm .rs-dropdown-toggle-caret {
  top: 5px;
  right: 10px;
}
.rs-dropdown-toggle.rs-btn-xs {
  padding-right: 28px !important;
}
.rs-dropdown-toggle.rs-btn-xs .rs-dropdown-toggle-caret {
  top: 2px;
  right: 8px;
}
.rs-dropdown-toggle,
.rs-dropdown-toggle.rs-btn {
  position: relative;
  z-index: 5;
  padding-right: 32px;
  display: inline-block;
  outline: none;
  cursor: pointer;
}
.rs-dropdown-toggle-custom-title,
.rs-dropdown-toggle.rs-btn-custom-title {
  padding: 0 !important;
}
.rs-dropdown-no-caret .rs-dropdown-toggle {
  padding-right: 12px;
}
.rs-dropdown-menu {
  margin: 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  border-radius: 4px;
  padding: 4px 0;
}
.rs-dropdown-menu .rs-dropdown-item-divider {
  height: 1px;
  margin: 6px 0;
  overflow: hidden;
  background-color: #e5e5ea;
}
.rs-dropdown-menu .rs-dropdown-item-content {
  display: block;
  padding: 8px 12px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #191919;
  white-space: nowrap;
  cursor: pointer;
  transition: color 0.3s linear, background-color 0.3s linear;
}
.rs-dropdown-menu .rs-dropdown-item-content > .rs-icon {
  width: 14px;
  text-align: center;
  margin-right: 6px;
}
.rs-dropdown-menu a.rs-dropdown-item-content,
.rs-dropdown-menu a.rs-dropdown-item-content:hover,
.rs-dropdown-menu a.rs-dropdown-item-content:focus,
.rs-dropdown-menu a.rs-dropdown-item-content:active {
  text-decoration: none;
}
.rs-dropdown-item-submenu {
  position: relative;
}
.rs-dropdown-item-submenu > .rs-dropdown-item-content {
  position: relative;
}
.rs-dropdown-item-submenu > .rs-dropdown-item-content > .rs-dropdown-menu-toggle {
  display: inline-block;
}
.rs-dropdown-item-submenu > .rs-dropdown-item-content > .rs-dropdown-menu-toggle .rs-dropdown-menu-toggle-icon {
  position: absolute;
  width: 5px;
  line-height: 1.42857143;
  font-size: 14px;
}
.rs-dropdown-item-submenu > .rs-dropdown-item-content > .rs-dropdown-menu {
  position: absolute;
  top: 0;
}
.rs-dropdown-item-submenu.rs-dropdown-menu-pull-right > .rs-dropdown-item-content {
  padding-right: 27px;
}
.rs-dropdown-item-submenu.rs-dropdown-menu-pull-right > .rs-dropdown-item-content > .rs-dropdown-menu-toggle .rs-dropdown-menu-toggle-icon {
  right: 12px;
}
.rs-dropdown-item-submenu.rs-dropdown-menu-pull-right > .rs-dropdown-item-content > .rs-dropdown-menu-toggle .rs-dropdown-menu-toggle-icon::before {
  content: '\ea0c' /* rtl: "\ea0a" */;
}
.rs-dropdown-item-submenu.rs-dropdown-menu-pull-left > .rs-dropdown-item-content {
  padding-left: 27px;
}
.rs-dropdown-item-submenu.rs-dropdown-menu-pull-left > .rs-dropdown-item-content > .rs-dropdown-menu-toggle .rs-dropdown-menu-toggle-icon {
  left: 12px;
}
.rs-dropdown-item-submenu.rs-dropdown-menu-pull-left > .rs-dropdown-item-content > .rs-dropdown-menu-toggle .rs-dropdown-menu-toggle-icon::before {
  content: '\ea0a' /* rtl: "\ea0c" */;
}
.rs-dropdown-item-submenu.rs-dropdown-menu-pull-right > .rs-dropdown-item-content > .rs-dropdown-menu {
  left: 100%;
}
.rs-dropdown-item-submenu.rs-dropdown-menu-pull-left > .rs-dropdown-item-content > .rs-dropdown-menu {
  right: 100%;
}
.rs-dropdown-item-submenu.rs-dropdown-item-open > .rs-dropdown-item-content > .rs-dropdown-menu {
  display: table;
  animation-name: slideUpIn;
  animation-name: none\9;
  animation-play-state: running;
}
.rs-dropdown-menu > .rs-dropdown-item > .rs-dropdown-item-content:hover,
.rs-dropdown-menu > .rs-dropdown-item > .rs-dropdown-item-content:focus {
  text-decoration: none;
  color: #191919;
  background-color: #fff2f4;
}
.rs-dropdown-menu > .rs-dropdown-menu-item-focus > .rs-dropdown-item-content,
.rs-dropdown-menu > .rs-dropdown-item-active > .rs-dropdown-item-content {
  font-weight: bold;
  outline: 0;
}
.rs-dropdown-menu > .rs-dropdown-menu-item-focus > .rs-dropdown-item-content,
.rs-dropdown-menu > .rs-dropdown-item-active > .rs-dropdown-item-content,
.rs-dropdown-menu > .rs-dropdown-menu-item-focus > .rs-dropdown-item-content:hover,
.rs-dropdown-menu > .rs-dropdown-item-active > .rs-dropdown-item-content:hover,
.rs-dropdown-menu > .rs-dropdown-menu-item-focus > .rs-dropdown-item-content:focus,
.rs-dropdown-menu > .rs-dropdown-item-active > .rs-dropdown-item-content:focus {
  text-decoration: none;
  background-color: rgba(255, 242, 244, 0.5);
}
.rs-dropdown-menu > .rs-dropdown-item-active > .rs-dropdown-item-content,
.rs-dropdown-menu > .rs-dropdown-item-active > .rs-dropdown-item-content:hover,
.rs-dropdown-menu > .rs-dropdown-item-active > .rs-dropdown-item-content:focus {
  color: #bd0003;
}
.rs-dropdown-menu > .rs-dropdown-item-disabled > .rs-dropdown-item-content {
  opacity: 0.3;
}
.rs-dropdown-menu > .rs-dropdown-item-disabled > .rs-dropdown-item-content,
.rs-dropdown-menu > .rs-dropdown-item-disabled > .rs-dropdown-item-content:hover,
.rs-dropdown-menu > .rs-dropdown-item-disabled > .rs-dropdown-item-content:focus {
  color: #191919;
}
.rs-dropdown-menu > .rs-dropdown-item-disabled > .rs-dropdown-item-content:hover,
.rs-dropdown-menu > .rs-dropdown-item-disabled > .rs-dropdown-item-content:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  cursor: not-allowed;
}
.rs-dropdown-open > .rs-dropdown-menu {
  display: block;
  animation-play-state: running;
}
.rs-dropdown-open.rs-dropdown-placement-top-start > .rs-dropdown-menu,
.rs-dropdown-open.rs-dropdown-placement-top-end > .rs-dropdown-menu {
  bottom: 100%;
}
.rs-dropdown-open.rs-dropdown-placement-left-start > .rs-dropdown-menu,
.rs-dropdown-open.rs-dropdown-placement-left-end > .rs-dropdown-menu {
  right: 100%;
}
.rs-dropdown-open.rs-dropdown-placement-right-start > .rs-dropdown-menu,
.rs-dropdown-open.rs-dropdown-placement-right-end > .rs-dropdown-menu {
  left: 100%;
}
.rs-dropdown-open.rs-dropdown-placement-bottom-start > .rs-dropdown-menu,
.rs-dropdown-open.rs-dropdown-placement-bottom-end > .rs-dropdown-menu {
  top: 100%;
}
.rs-dropdown-open.rs-dropdown-placement-top-start > .rs-dropdown-menu,
.rs-dropdown-open.rs-dropdown-placement-bottom-start > .rs-dropdown-menu {
  left: 0;
}
.rs-dropdown-open.rs-dropdown-placement-top-end > .rs-dropdown-menu,
.rs-dropdown-open.rs-dropdown-placement-bottom-end > .rs-dropdown-menu {
  right: 0;
}
.rs-dropdown-open.rs-dropdown-placement-left-start > .rs-dropdown-menu,
.rs-dropdown-open.rs-dropdown-placement-right-start > .rs-dropdown-menu {
  top: 0;
}
.rs-dropdown-open.rs-dropdown-placement-right-end > .rs-dropdown-menu,
.rs-dropdown-open.rs-dropdown-placement-left-end > .rs-dropdown-menu {
  bottom: 0;
}
.rs-dropdown-open > a {
  outline: 0;
}
.rs-dropdown-header {
  display: block;
  padding: 8px 12px;
  line-height: 1.42857143;
  color: #a6a6a6;
  border-bottom: 1px solid #e5e5ea;
  white-space: nowrap;
}
.rs-picker-toggle-wrapper {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
}
.rs-picker-toggle.rs-btn .rs-ripple-pond {
  display: none !important;
}
.rs-picker-block {
  display: block;
}
.rs-picker-disabled {
  opacity: 0.3;
}
.rs-picker-toggle .rs-picker-toggle-placeholder {
  color: #8e8e93;
}
.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #000000E6;
}
.rs-picker-none {
  padding: 6px 12px 12px;
  color: #8e8e93;
  cursor: default;
}
.rs-picker-countable .rs-picker-toggle-value {
  display: flex;
}
.rs-picker-value-list {
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block\9;
  max-width: 100%\9;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-picker-value-list {
    max-width: 100%;
  }
}
.rs-picker-value-count {
  margin: 0 4px;
  background: #D40511;
  color: #fff;
  border-radius: 10px;
  padding: 0 8px;
  line-height: 20px;
}
.rs-picker-value-separator {
  margin: 0 4px 0 0;
}
.rs-picker-toggle {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  outline: 0 !important;
  white-space: nowrap;
  border: none;
  user-select: none;
  /* stylelint-disable */
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  /* stylelint-enable */
}
.rs-picker-toggle.rs-btn-ghost {
  padding-top: 7px;
  padding-bottom: 7px;
}
.rs-picker-toggle.rs-btn-icon {
  padding-left: 24px;
  height: 36px;
  position: relative;
}
.rs-btn-ghost.rs-picker-toggle.rs-btn-icon {
  padding-left: 22px;
}
.rs-picker-toggle.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left {
  padding-left: 44px;
}
.rs-picker-toggle.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right {
  padding-left: 12px;
  padding-right: 44px;
}
.rs-picker-toggle.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon {
  left: auto;
  right: 0;
}
.rs-picker-toggle.rs-btn-icon > .rs-icon {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 36px;
  height: 36px;
  padding: 8px 0;
  font-size: 14px;
  line-height: 1.42857143;
}
.rs-btn-ghost.rs-picker-toggle.rs-btn-icon > .rs-icon {
  width: 34px;
  height: 34px;
  padding: 7px 0;
}
.rs-picker-toggle.rs-btn-icon > .rs-icon > svg {
  height: 14px;
  vertical-align: middle;
}
.rs-picker-toggle.rs-btn-icon-circle {
  width: 36px;
  padding: 8px;
}
.rs-picker-toggle:hover,
.rs-picker-toggle:focus {
  color: #191919;
  text-decoration: none;
}
.rs-picker-toggle:active,
.rs-picker-toggle.rs-btn-active {
  outline: 0;
  background-image: none;
}
.rs-picker-default .rs-picker-toggle {
  padding: 7px 11px;
}
.rs-picker-default .rs-btn,
.rs-picker-default .rs-picker-toggle {
  transition: border-color ease-in-out 0.3s;
}
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn.active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active {
  border-color: #bd0003;
}
.rs-picker-subtle .rs-btn,
.rs-picker-subtle .rs-picker-toggle {
  transition: color 0.2s linear, background-color 0.3s linear;
  color: #8e8e93;
  background-color: transparent;
  transition: none;
}
.rs-picker-subtle .rs-btn.rs-btn-loading:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-loading:hover,
.rs-picker-subtle .rs-btn.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-picker-subtle .rs-btn.rs-btn-loading:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-loading:focus,
.rs-picker-subtle .rs-btn.rs-btn-loading:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-loading:active,
.rs-picker-subtle .rs-btn.rs-btn-loading:hover:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-loading:hover:active {
  color: #8e8e93;
  background-color: transparent;
}
.rs-picker-subtle .rs-btn:focus,
.rs-picker-subtle .rs-picker-toggle:focus,
.rs-picker-subtle .rs-btn.rs-btn-focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-focus,
.rs-picker-subtle .rs-btn:hover,
.rs-picker-subtle .rs-picker-toggle:hover {
  color: #fff;
  background: #f7f7fa;
}
.rs-picker-subtle .rs-btn:active,
.rs-picker-subtle .rs-picker-toggle:active,
.rs-picker-subtle .rs-btn.rs-btn-active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle {
  color: #fff;
  background-color: #b2b1f0;
  background-image: none;
}
.rs-picker-subtle .rs-btn:active:hover,
.rs-picker-subtle .rs-picker-toggle:active:hover,
.rs-picker-subtle .rs-btn.rs-btn-active:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn:hover,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle:hover,
.rs-picker-subtle .rs-btn:active:focus,
.rs-picker-subtle .rs-picker-toggle:active:focus,
.rs-picker-subtle .rs-btn.rs-btn-active:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn:focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle:focus,
.rs-picker-subtle .rs-btn:active.focus,
.rs-picker-subtle .rs-picker-toggle:active.focus,
.rs-picker-subtle .rs-btn.rs-btn-active.focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.focus {
  color: #fff;
  background-color: #b2b1f0;
}
.rs-picker-subtle .rs-btn.rs-btn-disabled,
.rs-picker-subtle .rs-picker-toggle.rs-btn-disabled,
.rs-picker-subtle .rs-btn.rs-btn-disabled:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-disabled:hover,
.rs-picker-subtle .rs-btn.rs-btn-disabled:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-disabled:focus,
.rs-picker-subtle .rs-btn.rs-btn-disabled.focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-disabled.focus,
.rs-picker-subtle .rs-btn.rs-btn-disabled:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-disabled:active,
.rs-picker-subtle .rs-btn.rs-btn-disabled:hover:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-disabled:hover:active {
  color: #c5c6c7;
  background: none;
}
.rs-picker-subtle .rs-btn.rs-btn-loading:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-loading:hover,
.rs-picker-subtle .rs-btn.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-picker-subtle .rs-btn.rs-btn-loading:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-loading:focus,
.rs-picker-subtle .rs-btn.rs-btn-loading:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-loading:active,
.rs-picker-subtle .rs-btn.rs-btn-loading:hover:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-loading:hover:active {
  color: #8e8e93;
  background-color: transparent;
}
.rs-picker-subtle .rs-btn.rs-btn-disabled,
.rs-picker-subtle .rs-picker-toggle.rs-btn-disabled,
.rs-picker-subtle .rs-btn.rs-btn-disabled:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-disabled:hover,
.rs-picker-subtle .rs-btn.rs-btn-disabled:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-disabled:focus,
.rs-picker-subtle .rs-btn.rs-btn-disabled:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-disabled:active {
  opacity: 1;
}
.rs-picker-subtle .rs-btn:focus,
.rs-picker-subtle .rs-picker-toggle:focus,
.rs-picker-subtle .rs-btn.rs-btn-focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-focus,
.rs-picker-subtle .rs-btn:hover,
.rs-picker-subtle .rs-picker-toggle:hover {
  color: #575757;
}
.rs-picker-subtle .rs-btn:active,
.rs-picker-subtle .rs-picker-toggle:active,
.rs-picker-subtle .rs-btn.rs-btn-active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle {
  color: #272c36;
  background-color: #e5e5ea;
  background-image: none;
}
.rs-picker-subtle .rs-btn:active:hover,
.rs-picker-subtle .rs-picker-toggle:active:hover,
.rs-picker-subtle .rs-btn.rs-btn-active:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn:hover,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle:hover,
.rs-picker-subtle .rs-btn:active:focus,
.rs-picker-subtle .rs-picker-toggle:active:focus,
.rs-picker-subtle .rs-btn.rs-btn-active:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn:focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle:focus,
.rs-picker-subtle .rs-btn:active.focus,
.rs-picker-subtle .rs-picker-toggle:active.focus,
.rs-picker-subtle .rs-btn.rs-btn-active.focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.focus {
  color: #272c36;
  background-color: #e5e5ea;
}
.rs-picker-subtle .rs-btn.rs-btn-red,
.rs-picker-subtle .rs-picker-toggle.rs-btn-red {
  color: #8e8e93;
  background-color: transparent;
}
.rs-picker-subtle .rs-btn.rs-btn-red.rs-btn-loading:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-red.rs-btn-loading:hover,
.rs-picker-subtle .rs-btn.rs-btn-red.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-red.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-picker-subtle .rs-btn.rs-btn-red.rs-btn-loading:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-red.rs-btn-loading:focus,
.rs-picker-subtle .rs-btn.rs-btn-red.rs-btn-loading:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-red.rs-btn-loading:active,
.rs-picker-subtle .rs-btn.rs-btn-red.rs-btn-loading:hover:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-red.rs-btn-loading:hover:active {
  color: #8e8e93;
  background-color: transparent;
}
.rs-picker-subtle .rs-btn.rs-btn-red:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-red:focus,
.rs-picker-subtle .rs-btn.rs-btn-red.rs-btn-focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-red.rs-btn-focus,
.rs-picker-subtle .rs-btn.rs-btn-red:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-red:hover {
  color: #fff;
  background: #f44336;
}
.rs-picker-subtle .rs-btn.rs-btn-red:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-red:active,
.rs-picker-subtle .rs-btn.rs-btn-red.rs-btn-active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-red.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-red,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-red {
  color: #fff;
  background-color: #eb3626;
  background-image: none;
}
.rs-picker-subtle .rs-btn.rs-btn-red:active:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-red:active:hover,
.rs-picker-subtle .rs-btn.rs-btn-red.rs-btn-active:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-red.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-red:hover,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-red:hover,
.rs-picker-subtle .rs-btn.rs-btn-red:active:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-red:active:focus,
.rs-picker-subtle .rs-btn.rs-btn-red.rs-btn-active:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-red.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-red:focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-red:focus,
.rs-picker-subtle .rs-btn.rs-btn-red:active.focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-red:active.focus,
.rs-picker-subtle .rs-btn.rs-btn-red.rs-btn-active.focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-red.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-red.focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-red.focus {
  color: #fff;
  background-color: #eb3626;
}
.rs-picker-subtle .rs-btn.rs-btn-red.rs-btn-disabled,
.rs-picker-subtle .rs-picker-toggle.rs-btn-red.rs-btn-disabled,
.rs-picker-subtle .rs-btn.rs-btn-red.rs-btn-disabled:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-red.rs-btn-disabled:hover,
.rs-picker-subtle .rs-btn.rs-btn-red.rs-btn-disabled:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-red.rs-btn-disabled:focus,
.rs-picker-subtle .rs-btn.rs-btn-red.rs-btn-disabled.focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-red.rs-btn-disabled.focus,
.rs-picker-subtle .rs-btn.rs-btn-red.rs-btn-disabled:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-red.rs-btn-disabled:active,
.rs-picker-subtle .rs-btn.rs-btn-red.rs-btn-disabled:hover:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-red.rs-btn-disabled:hover:active {
  color: #c5c6c7;
  background: none;
}
.rs-picker-subtle .rs-btn.rs-btn-orange,
.rs-picker-subtle .rs-picker-toggle.rs-btn-orange {
  color: #8e8e93;
  background-color: transparent;
}
.rs-picker-subtle .rs-btn.rs-btn-orange.rs-btn-loading:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-orange.rs-btn-loading:hover,
.rs-picker-subtle .rs-btn.rs-btn-orange.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-orange.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-picker-subtle .rs-btn.rs-btn-orange.rs-btn-loading:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-orange.rs-btn-loading:focus,
.rs-picker-subtle .rs-btn.rs-btn-orange.rs-btn-loading:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-orange.rs-btn-loading:active,
.rs-picker-subtle .rs-btn.rs-btn-orange.rs-btn-loading:hover:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-orange.rs-btn-loading:hover:active {
  color: #8e8e93;
  background-color: transparent;
}
.rs-picker-subtle .rs-btn.rs-btn-orange:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-orange:focus,
.rs-picker-subtle .rs-btn.rs-btn-orange.rs-btn-focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-orange.rs-btn-focus,
.rs-picker-subtle .rs-btn.rs-btn-orange:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-orange:hover {
  color: #fff;
  background: #ff9800;
}
.rs-picker-subtle .rs-btn.rs-btn-orange:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-orange:active,
.rs-picker-subtle .rs-btn.rs-btn-orange.rs-btn-active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-orange.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-orange,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-orange {
  color: #fff;
  background-color: #f59700;
  background-image: none;
}
.rs-picker-subtle .rs-btn.rs-btn-orange:active:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-orange:active:hover,
.rs-picker-subtle .rs-btn.rs-btn-orange.rs-btn-active:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-orange.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-orange:hover,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-orange:hover,
.rs-picker-subtle .rs-btn.rs-btn-orange:active:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-orange:active:focus,
.rs-picker-subtle .rs-btn.rs-btn-orange.rs-btn-active:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-orange.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-orange:focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-orange:focus,
.rs-picker-subtle .rs-btn.rs-btn-orange:active.focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-orange:active.focus,
.rs-picker-subtle .rs-btn.rs-btn-orange.rs-btn-active.focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-orange.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-orange.focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-orange.focus {
  color: #fff;
  background-color: #f59700;
}
.rs-picker-subtle .rs-btn.rs-btn-orange.rs-btn-disabled,
.rs-picker-subtle .rs-picker-toggle.rs-btn-orange.rs-btn-disabled,
.rs-picker-subtle .rs-btn.rs-btn-orange.rs-btn-disabled:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-orange.rs-btn-disabled:hover,
.rs-picker-subtle .rs-btn.rs-btn-orange.rs-btn-disabled:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-orange.rs-btn-disabled:focus,
.rs-picker-subtle .rs-btn.rs-btn-orange.rs-btn-disabled.focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-orange.rs-btn-disabled.focus,
.rs-picker-subtle .rs-btn.rs-btn-orange.rs-btn-disabled:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-orange.rs-btn-disabled:active,
.rs-picker-subtle .rs-btn.rs-btn-orange.rs-btn-disabled:hover:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-orange.rs-btn-disabled:hover:active {
  color: #c5c6c7;
  background: none;
}
.rs-picker-subtle .rs-btn.rs-btn-yellow,
.rs-picker-subtle .rs-picker-toggle.rs-btn-yellow {
  color: #8e8e93;
  background-color: transparent;
}
.rs-picker-subtle .rs-btn.rs-btn-yellow.rs-btn-loading:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-yellow.rs-btn-loading:hover,
.rs-picker-subtle .rs-btn.rs-btn-yellow.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-yellow.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-picker-subtle .rs-btn.rs-btn-yellow.rs-btn-loading:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-yellow.rs-btn-loading:focus,
.rs-picker-subtle .rs-btn.rs-btn-yellow.rs-btn-loading:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-yellow.rs-btn-loading:active,
.rs-picker-subtle .rs-btn.rs-btn-yellow.rs-btn-loading:hover:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-yellow.rs-btn-loading:hover:active {
  color: #8e8e93;
  background-color: transparent;
}
.rs-picker-subtle .rs-btn.rs-btn-yellow:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-yellow:focus,
.rs-picker-subtle .rs-btn.rs-btn-yellow.rs-btn-focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-yellow.rs-btn-focus,
.rs-picker-subtle .rs-btn.rs-btn-yellow:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-yellow:hover {
  color: #fff;
  background: #ffca28;
}
.rs-picker-subtle .rs-btn.rs-btn-yellow:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-yellow:active,
.rs-picker-subtle .rs-btn.rs-btn-yellow.rs-btn-active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-yellow.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-yellow,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-yellow {
  color: #fff;
  background-color: #f5c31d;
  background-image: none;
}
.rs-picker-subtle .rs-btn.rs-btn-yellow:active:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-yellow:active:hover,
.rs-picker-subtle .rs-btn.rs-btn-yellow.rs-btn-active:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-yellow.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-yellow:hover,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-yellow:hover,
.rs-picker-subtle .rs-btn.rs-btn-yellow:active:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-yellow:active:focus,
.rs-picker-subtle .rs-btn.rs-btn-yellow.rs-btn-active:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-yellow.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-yellow:focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-yellow:focus,
.rs-picker-subtle .rs-btn.rs-btn-yellow:active.focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-yellow:active.focus,
.rs-picker-subtle .rs-btn.rs-btn-yellow.rs-btn-active.focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-yellow.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-yellow.focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-yellow.focus {
  color: #fff;
  background-color: #f5c31d;
}
.rs-picker-subtle .rs-btn.rs-btn-yellow.rs-btn-disabled,
.rs-picker-subtle .rs-picker-toggle.rs-btn-yellow.rs-btn-disabled,
.rs-picker-subtle .rs-btn.rs-btn-yellow.rs-btn-disabled:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-yellow.rs-btn-disabled:hover,
.rs-picker-subtle .rs-btn.rs-btn-yellow.rs-btn-disabled:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-yellow.rs-btn-disabled:focus,
.rs-picker-subtle .rs-btn.rs-btn-yellow.rs-btn-disabled.focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-yellow.rs-btn-disabled.focus,
.rs-picker-subtle .rs-btn.rs-btn-yellow.rs-btn-disabled:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-yellow.rs-btn-disabled:active,
.rs-picker-subtle .rs-btn.rs-btn-yellow.rs-btn-disabled:hover:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-yellow.rs-btn-disabled:hover:active {
  color: #c5c6c7;
  background: none;
}
.rs-picker-subtle .rs-btn.rs-btn-green,
.rs-picker-subtle .rs-picker-toggle.rs-btn-green {
  color: #8e8e93;
  background-color: transparent;
}
.rs-picker-subtle .rs-btn.rs-btn-green.rs-btn-loading:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-green.rs-btn-loading:hover,
.rs-picker-subtle .rs-btn.rs-btn-green.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-green.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-picker-subtle .rs-btn.rs-btn-green.rs-btn-loading:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-green.rs-btn-loading:focus,
.rs-picker-subtle .rs-btn.rs-btn-green.rs-btn-loading:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-green.rs-btn-loading:active,
.rs-picker-subtle .rs-btn.rs-btn-green.rs-btn-loading:hover:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-green.rs-btn-loading:hover:active {
  color: #8e8e93;
  background-color: transparent;
}
.rs-picker-subtle .rs-btn.rs-btn-green:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-green:focus,
.rs-picker-subtle .rs-btn.rs-btn-green.rs-btn-focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-green.rs-btn-focus,
.rs-picker-subtle .rs-btn.rs-btn-green:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-green:hover {
  color: #fff;
  background: #4caf50;
}
.rs-picker-subtle .rs-btn.rs-btn-green:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-green:active,
.rs-picker-subtle .rs-btn.rs-btn-green.rs-btn-active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-green.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-green,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-green {
  color: #fff;
  background-color: #37ab3c;
  background-image: none;
}
.rs-picker-subtle .rs-btn.rs-btn-green:active:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-green:active:hover,
.rs-picker-subtle .rs-btn.rs-btn-green.rs-btn-active:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-green.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-green:hover,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-green:hover,
.rs-picker-subtle .rs-btn.rs-btn-green:active:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-green:active:focus,
.rs-picker-subtle .rs-btn.rs-btn-green.rs-btn-active:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-green.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-green:focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-green:focus,
.rs-picker-subtle .rs-btn.rs-btn-green:active.focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-green:active.focus,
.rs-picker-subtle .rs-btn.rs-btn-green.rs-btn-active.focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-green.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-green.focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-green.focus {
  color: #fff;
  background-color: #37ab3c;
}
.rs-picker-subtle .rs-btn.rs-btn-green.rs-btn-disabled,
.rs-picker-subtle .rs-picker-toggle.rs-btn-green.rs-btn-disabled,
.rs-picker-subtle .rs-btn.rs-btn-green.rs-btn-disabled:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-green.rs-btn-disabled:hover,
.rs-picker-subtle .rs-btn.rs-btn-green.rs-btn-disabled:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-green.rs-btn-disabled:focus,
.rs-picker-subtle .rs-btn.rs-btn-green.rs-btn-disabled.focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-green.rs-btn-disabled.focus,
.rs-picker-subtle .rs-btn.rs-btn-green.rs-btn-disabled:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-green.rs-btn-disabled:active,
.rs-picker-subtle .rs-btn.rs-btn-green.rs-btn-disabled:hover:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-green.rs-btn-disabled:hover:active {
  color: #c5c6c7;
  background: none;
}
.rs-picker-subtle .rs-btn.rs-btn-cyan,
.rs-picker-subtle .rs-picker-toggle.rs-btn-cyan {
  color: #8e8e93;
  background-color: transparent;
}
.rs-picker-subtle .rs-btn.rs-btn-cyan.rs-btn-loading:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-cyan.rs-btn-loading:hover,
.rs-picker-subtle .rs-btn.rs-btn-cyan.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-cyan.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-picker-subtle .rs-btn.rs-btn-cyan.rs-btn-loading:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-cyan.rs-btn-loading:focus,
.rs-picker-subtle .rs-btn.rs-btn-cyan.rs-btn-loading:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-cyan.rs-btn-loading:active,
.rs-picker-subtle .rs-btn.rs-btn-cyan.rs-btn-loading:hover:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-cyan.rs-btn-loading:hover:active {
  color: #8e8e93;
  background-color: transparent;
}
.rs-picker-subtle .rs-btn.rs-btn-cyan:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-cyan:focus,
.rs-picker-subtle .rs-btn.rs-btn-cyan.rs-btn-focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-cyan.rs-btn-focus,
.rs-picker-subtle .rs-btn.rs-btn-cyan:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-cyan:hover {
  color: #fff;
  background: #00bcd4;
}
.rs-picker-subtle .rs-btn.rs-btn-cyan:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-cyan:active,
.rs-picker-subtle .rs-btn.rs-btn-cyan.rs-btn-active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-cyan.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-cyan,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-cyan {
  color: #fff;
  background-color: #00b1cc;
  background-image: none;
}
.rs-picker-subtle .rs-btn.rs-btn-cyan:active:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-cyan:active:hover,
.rs-picker-subtle .rs-btn.rs-btn-cyan.rs-btn-active:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-cyan.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-cyan:hover,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-cyan:hover,
.rs-picker-subtle .rs-btn.rs-btn-cyan:active:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-cyan:active:focus,
.rs-picker-subtle .rs-btn.rs-btn-cyan.rs-btn-active:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-cyan.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-cyan:focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-cyan:focus,
.rs-picker-subtle .rs-btn.rs-btn-cyan:active.focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-cyan:active.focus,
.rs-picker-subtle .rs-btn.rs-btn-cyan.rs-btn-active.focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-cyan.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-cyan.focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-cyan.focus {
  color: #fff;
  background-color: #00b1cc;
}
.rs-picker-subtle .rs-btn.rs-btn-cyan.rs-btn-disabled,
.rs-picker-subtle .rs-picker-toggle.rs-btn-cyan.rs-btn-disabled,
.rs-picker-subtle .rs-btn.rs-btn-cyan.rs-btn-disabled:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-cyan.rs-btn-disabled:hover,
.rs-picker-subtle .rs-btn.rs-btn-cyan.rs-btn-disabled:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-cyan.rs-btn-disabled:focus,
.rs-picker-subtle .rs-btn.rs-btn-cyan.rs-btn-disabled.focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-cyan.rs-btn-disabled.focus,
.rs-picker-subtle .rs-btn.rs-btn-cyan.rs-btn-disabled:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-cyan.rs-btn-disabled:active,
.rs-picker-subtle .rs-btn.rs-btn-cyan.rs-btn-disabled:hover:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-cyan.rs-btn-disabled:hover:active {
  color: #c5c6c7;
  background: none;
}
.rs-picker-subtle .rs-btn.rs-btn-blue,
.rs-picker-subtle .rs-picker-toggle.rs-btn-blue {
  color: #8e8e93;
  background-color: transparent;
}
.rs-picker-subtle .rs-btn.rs-btn-blue.rs-btn-loading:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-blue.rs-btn-loading:hover,
.rs-picker-subtle .rs-btn.rs-btn-blue.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-blue.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-picker-subtle .rs-btn.rs-btn-blue.rs-btn-loading:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-blue.rs-btn-loading:focus,
.rs-picker-subtle .rs-btn.rs-btn-blue.rs-btn-loading:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-blue.rs-btn-loading:active,
.rs-picker-subtle .rs-btn.rs-btn-blue.rs-btn-loading:hover:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-blue.rs-btn-loading:hover:active {
  color: #8e8e93;
  background-color: transparent;
}
.rs-picker-subtle .rs-btn.rs-btn-blue:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-blue:focus,
.rs-picker-subtle .rs-btn.rs-btn-blue.rs-btn-focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-blue.rs-btn-focus,
.rs-picker-subtle .rs-btn.rs-btn-blue:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-blue:hover {
  color: #fff;
  background: #2196f3;
}
.rs-picker-subtle .rs-btn.rs-btn-blue:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-blue:active,
.rs-picker-subtle .rs-btn.rs-btn-blue.rs-btn-active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-blue.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-blue,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-blue {
  color: #fff;
  background-color: #1787e8;
  background-image: none;
}
.rs-picker-subtle .rs-btn.rs-btn-blue:active:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-blue:active:hover,
.rs-picker-subtle .rs-btn.rs-btn-blue.rs-btn-active:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-blue.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-blue:hover,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-blue:hover,
.rs-picker-subtle .rs-btn.rs-btn-blue:active:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-blue:active:focus,
.rs-picker-subtle .rs-btn.rs-btn-blue.rs-btn-active:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-blue.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-blue:focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-blue:focus,
.rs-picker-subtle .rs-btn.rs-btn-blue:active.focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-blue:active.focus,
.rs-picker-subtle .rs-btn.rs-btn-blue.rs-btn-active.focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-blue.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-blue.focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-blue.focus {
  color: #fff;
  background-color: #1787e8;
}
.rs-picker-subtle .rs-btn.rs-btn-blue.rs-btn-disabled,
.rs-picker-subtle .rs-picker-toggle.rs-btn-blue.rs-btn-disabled,
.rs-picker-subtle .rs-btn.rs-btn-blue.rs-btn-disabled:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-blue.rs-btn-disabled:hover,
.rs-picker-subtle .rs-btn.rs-btn-blue.rs-btn-disabled:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-blue.rs-btn-disabled:focus,
.rs-picker-subtle .rs-btn.rs-btn-blue.rs-btn-disabled.focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-blue.rs-btn-disabled.focus,
.rs-picker-subtle .rs-btn.rs-btn-blue.rs-btn-disabled:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-blue.rs-btn-disabled:active,
.rs-picker-subtle .rs-btn.rs-btn-blue.rs-btn-disabled:hover:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-blue.rs-btn-disabled:hover:active {
  color: #c5c6c7;
  background: none;
}
.rs-picker-subtle .rs-btn.rs-btn-violet,
.rs-picker-subtle .rs-picker-toggle.rs-btn-violet {
  color: #8e8e93;
  background-color: transparent;
}
.rs-picker-subtle .rs-btn.rs-btn-violet.rs-btn-loading:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-violet.rs-btn-loading:hover,
.rs-picker-subtle .rs-btn.rs-btn-violet.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-violet.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-picker-subtle .rs-btn.rs-btn-violet.rs-btn-loading:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-violet.rs-btn-loading:focus,
.rs-picker-subtle .rs-btn.rs-btn-violet.rs-btn-loading:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-violet.rs-btn-loading:active,
.rs-picker-subtle .rs-btn.rs-btn-violet.rs-btn-loading:hover:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-violet.rs-btn-loading:hover:active {
  color: #8e8e93;
  background-color: transparent;
}
.rs-picker-subtle .rs-btn.rs-btn-violet:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-violet:focus,
.rs-picker-subtle .rs-btn.rs-btn-violet.rs-btn-focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-violet.rs-btn-focus,
.rs-picker-subtle .rs-btn.rs-btn-violet:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-violet:hover {
  color: #fff;
  background: #673ab7;
}
.rs-picker-subtle .rs-btn.rs-btn-violet:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-violet:active,
.rs-picker-subtle .rs-btn.rs-btn-violet.rs-btn-active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-violet.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-violet,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-violet {
  color: #fff;
  background-color: #5f2bb3;
  background-image: none;
}
.rs-picker-subtle .rs-btn.rs-btn-violet:active:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-violet:active:hover,
.rs-picker-subtle .rs-btn.rs-btn-violet.rs-btn-active:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-violet.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-violet:hover,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-violet:hover,
.rs-picker-subtle .rs-btn.rs-btn-violet:active:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-violet:active:focus,
.rs-picker-subtle .rs-btn.rs-btn-violet.rs-btn-active:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-violet.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-violet:focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-violet:focus,
.rs-picker-subtle .rs-btn.rs-btn-violet:active.focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-violet:active.focus,
.rs-picker-subtle .rs-btn.rs-btn-violet.rs-btn-active.focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-violet.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-violet.focus,
.rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-violet.focus {
  color: #fff;
  background-color: #5f2bb3;
}
.rs-picker-subtle .rs-btn.rs-btn-violet.rs-btn-disabled,
.rs-picker-subtle .rs-picker-toggle.rs-btn-violet.rs-btn-disabled,
.rs-picker-subtle .rs-btn.rs-btn-violet.rs-btn-disabled:hover,
.rs-picker-subtle .rs-picker-toggle.rs-btn-violet.rs-btn-disabled:hover,
.rs-picker-subtle .rs-btn.rs-btn-violet.rs-btn-disabled:focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-violet.rs-btn-disabled:focus,
.rs-picker-subtle .rs-btn.rs-btn-violet.rs-btn-disabled.focus,
.rs-picker-subtle .rs-picker-toggle.rs-btn-violet.rs-btn-disabled.focus,
.rs-picker-subtle .rs-btn.rs-btn-violet.rs-btn-disabled:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-violet.rs-btn-disabled:active,
.rs-picker-subtle .rs-btn.rs-btn-violet.rs-btn-disabled:hover:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-violet.rs-btn-disabled:hover:active {
  color: #c5c6c7;
  background: none;
}
.rs-picker-subtle .rs-btn.active,
.rs-picker-subtle .rs-picker-toggle.active {
  background-color: #f7f7fa;
  color: #575757;
}
/* stylelint-disable-next-line */
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn {
  padding-right: 32px;
}
.rs-picker-cleanable .rs-picker-toggle.rs-btn {
  padding-right: 44px;
}
.rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  top: 8px;
}
.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  top: 7px;
}
.rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  right: 12px;
}
.rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  right: 30px;
}
.rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding: 7px 12px;
  font-size: 14px;
  line-height: 1.42857143;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper {
  padding-bottom: 5px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper .rs-tag {
  margin-top: 5px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding-left: 12px;
  margin-top: 5px;
  font-size: 14px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper .rs-picker-search-input input {
  height: 18px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn {
  padding-top: 7px;
  padding-bottom: 7px;
}
.rs-picker-date .rs-picker-toggle.rs-btn,
.rs-picker-daterange .rs-picker-toggle.rs-btn {
  padding-right: 36px;
}
.rs-picker-cleanable.rs-picker-date .rs-picker-toggle.rs-btn,
.rs-picker-cleanable.rs-picker-daterange .rs-picker-toggle.rs-btn {
  padding-right: 42px;
}
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  top: 8px;
}
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  top: 7px;
}
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  right: 12px;
}
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  right: 30px;
}
.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 7px;
  padding-bottom: 7px;
}
.rs-picker-toggle.rs-btn-lg {
  /* stylelint-disable */
  padding: 10px 16px;
  font-size: 16px;
  line-height: 1.375;
  border-radius: 4px;
  /* stylelint-enable */
}
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-lg {
  padding-right: 36px;
}
.rs-picker-cleanable .rs-picker-toggle.rs-btn-lg {
  padding-right: 50px;
}
.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
  top: 10px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
  top: 9px;
}
.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
  right: 16px;
}
.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
  right: 32px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-lg {
  padding-left: 15px;
}
.rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding: 9px 16px;
  font-size: 16px;
  line-height: 1.375;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper {
  padding-bottom: 7px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper .rs-tag {
  margin-top: 7px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding-left: 16px;
  margin-top: 7px;
  font-size: 16px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper .rs-picker-search-input input {
  height: 20px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-lg {
  padding-top: 9px;
  padding-bottom: 9px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-lg,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg {
  padding-right: 42px;
}
.rs-picker-cleanable.rs-picker-date .rs-picker-toggle.rs-btn-lg,
.rs-picker-cleanable.rs-picker-daterange .rs-picker-toggle.rs-btn-lg {
  padding-right: 50px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
  top: 10px;
}
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
  top: 9px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
  right: 16px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
  right: 36px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-lg {
  padding-top: 9px;
  padding-bottom: 9px;
}
.rs-picker-toggle.rs-btn-lg .rs-picker-value-count {
  line-height: 22px;
}
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-md {
  padding-right: 32px;
}
.rs-picker-cleanable .rs-picker-toggle.rs-btn-md {
  padding-right: 44px;
}
.rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
.rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean {
  top: 8px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean {
  top: 7px;
}
.rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret {
  right: 12px;
}
.rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean {
  right: 30px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-md {
  padding-left: 11px;
}
.rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding: 7px 12px;
  font-size: 14px;
  line-height: 1.42857143;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper {
  padding-bottom: 5px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper .rs-tag {
  margin-top: 5px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding-left: 12px;
  margin-top: 5px;
  font-size: 14px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper .rs-picker-search-input input {
  height: 18px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-md {
  padding-top: 7px;
  padding-bottom: 7px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-md,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md {
  padding-right: 36px;
}
.rs-picker-cleanable.rs-picker-date .rs-picker-toggle.rs-btn-md,
.rs-picker-cleanable.rs-picker-daterange .rs-picker-toggle.rs-btn-md {
  padding-right: 42px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
.rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret {
  top: 8px;
}
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret {
  top: 7px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret {
  right: 12px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean {
  right: 30px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-md {
  padding-top: 7px;
  padding-bottom: 7px;
}
.rs-picker-toggle.rs-btn-sm {
  /* stylelint-disable */
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  /* stylelint-enable */
}
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-sm {
  padding-right: 30px;
}
.rs-picker-cleanable .rs-picker-toggle.rs-btn-sm {
  padding-right: 42px;
}
.rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
.rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean {
  top: 5px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean {
  top: 4px;
}
.rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret {
  right: 10px;
}
.rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean {
  right: 28px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-sm {
  padding-left: 9px;
}
.rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding: 4px 10px;
  font-size: 14px;
  line-height: 1.42857143;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper {
  padding-bottom: 2px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper .rs-tag {
  margin-top: 2px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding-left: 10px;
  margin-top: 2px;
  font-size: 14px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper .rs-picker-search-input input {
  height: 18px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-sm {
  padding-top: 4px;
  padding-bottom: 4px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-sm,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm {
  padding-right: 34px;
}
.rs-picker-cleanable.rs-picker-date .rs-picker-toggle.rs-btn-sm,
.rs-picker-cleanable.rs-picker-daterange .rs-picker-toggle.rs-btn-sm {
  padding-right: 40px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
.rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret {
  top: 4px;
}
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret {
  top: 3px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret {
  right: 10px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean {
  right: 28px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-sm {
  padding-top: 4px;
  padding-bottom: 4px;
}
.rs-picker-toggle.rs-btn-xs {
  /* stylelint-disable */
  padding: 2px 8px;
  font-size: 12px;
  line-height: 1.66666667;
  border-radius: 4px;
  /* stylelint-enable */
  /* stylelint-disable */
  /* stylelint-enable */
}
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-xs {
  padding-right: 28px;
}
.rs-picker-cleanable .rs-picker-toggle.rs-btn-xs {
  padding-right: 40px;
}
.rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
.rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean {
  top: 2px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean {
  top: 1px;
}
.rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret {
  right: 8px;
}
.rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean {
  right: 26px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-xs {
  padding-left: 7px;
}
.rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding: 1px 8px;
  font-size: 12px;
  line-height: 1.66666667;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper {
  padding-bottom: -1px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-tag {
  margin-top: -1px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding-left: 8px;
  margin-top: -1px;
  font-size: 12px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-picker-search-input input {
  height: 18px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs {
  padding-top: 1px;
  padding-bottom: 1px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper {
  padding-bottom: 1px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-tag {
  margin-top: 1px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding-top: 1px;
  padding-bottom: 1px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-xs,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs {
  padding-right: 30px;
}
.rs-picker-cleanable.rs-picker-date .rs-picker-toggle.rs-btn-xs,
.rs-picker-cleanable.rs-picker-daterange .rs-picker-toggle.rs-btn-xs {
  padding-right: 36px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
.rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret {
  top: 2px;
}
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret {
  top: 1px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret {
  right: 8px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean {
  right: 24px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-xs {
  padding-top: 1px;
  padding-bottom: 1px;
}
/* stylelint-disable-next-line */
.rs-picker-toggle {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.rs-picker-default .rs-picker-toggle {
  position: relative;
  z-index: 5;
  padding-right: 32px;
  display: inline-block;
  outline: none;
  cursor: pointer;
  color: #575757;
  border: 1px solid #e5e5ea;
}
.rs-picker-default .rs-picker-toggle-custom-title {
  padding: 0 !important;
}
.rs-picker-disabled .rs-picker-toggle {
  cursor: not-allowed;
}
.rs-picker-toggle .rs-picker-default:not(.rs-picker-disabled):hover,
.rs-picker-toggle .rs-picker-default:not(.rs-picker-disabled):focus {
  border-color: #bd0003;
}
.rs-picker-subtle .rs-picker-toggle {
  position: relative;
  z-index: 5;
  padding-right: 32px;
  display: inline-block;
  outline: none;
  cursor: pointer;
}
.rs-picker-subtle .rs-picker-toggle-custom-title {
  padding: 0 !important;
}
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle {
  cursor: not-allowed;
}
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:hover,
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:focus,
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:active {
  background: none;
}
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:hover::after,
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:focus::after,
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:active::after {
  display: none;
}
.rs-picker-toggle-value {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.rs-picker-cleanable .rs-picker-toggle {
  padding-right: 44px;
}
.rs-picker-date.rs-picker-cleanable .rs-picker-toggle,
.rs-picker-daterange.rs-picker-cleanable .rs-picker-toggle {
  padding-right: 52px;
}
.rs-picker-toggle-clean {
  display: inline-block;
  margin-left: 2px;
  position: absolute;
  top: 8px;
  right: 30px;
  font-weight: normal;
  background: inherit;
  color: #a6a6a6;
  transition: 0.2s color linear;
  cursor: pointer;
  text-align: center\9;
}
.rs-picker-toggle-clean::before {
  font-family: 'rsuite-icon-font' !important;
}
.dropdown-placement-left-end .rs-picker-toggle-clean::before,
.dropdown-placement-left-start .rs-picker-toggle-clean::before {
  content: '\ea0a' /* rtl: "\ea0c" */;
}
.dropdown-placement-right-end .rs-picker-toggle-clean::before,
.dropdown-placement-right-start .rs-picker-toggle-clean::before {
  content: '\ea0c' /* rtl: "\ea0a" */;
}
.dropdown-placement-top-start .rs-picker-toggle-clean::before,
.dropdown-placement-top-end .rs-picker-toggle-clean::before {
  content: '\ea0e';
}
.dropdown-placement-bottom-start .rs-picker-toggle-clean::before,
.dropdown-placement-bottom-end .rs-picker-toggle-clean::before {
  content: '\ea08';
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-picker-toggle-clean {
    text-align: center\9;
  }
}
.rs-picker-toggle-clean:hover {
  color: #f44336;
}
.rs-picker-date .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle-clean {
  right: 38px;
}
.rs-picker-toggle-caret {
  display: inline-block;
  margin-left: 2px;
  position: absolute;
  top: 8px;
  right: 12px;
  font-weight: normal;
  color: #8e8e93;
}
.rs-picker-toggle-caret::before {
  font-family: 'rsuite-icon-font' !important;
}
.rs-picker-placement-left-end .rs-picker-toggle-caret::before,
.rs-picker-placement-left-start .rs-picker-toggle-caret::before {
  content: '\ea0a' /* rtl: "\ea0c" */;
}
.rs-picker-placement-right-end .rs-picker-toggle-caret::before,
.rs-picker-placement-right-start .rs-picker-toggle-caret::before {
  content: '\ea0c' /* rtl: "\ea0a" */;
}
.rs-picker-placement-top-start .rs-picker-toggle-caret::before,
.rs-picker-placement-top-end .rs-picker-toggle-caret::before {
  content: '\ea0e';
}
.rs-picker-placement-bottom-start .rs-picker-toggle-caret::before,
.rs-picker-placement-bottom-end .rs-picker-toggle-caret::before {
  content: '\ea08';
}
.rs-picker-toggle-caret::before {
  content: '\ea08';
}
.rs-picker-menu {
  position: absolute;
  text-align: left;
  z-index: 7;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  transition: none;
  /* stylelint-disable-next-line */
}
.rs-modal-open .rs-picker-menu {
  z-index: 1055;
}
.rs-drawer-open .rs-picker-menu {
  z-index: 1055;
}
.rs-picker-menu.rs-picker-select-menu,
.rs-picker-menu.rs-picker-check-menu {
  padding-top: 4px;
}
.rs-picker-menu .rs-picker-search-bar {
  position: relative;
  padding: 8px 12px 12px;
}
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
  display: block;
  width: 100%;
  color: #575757;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e5e5ea;
  transition: border-color ease-in-out 0.3s;
  border-radius: 4px;
  /* stylelint-disable */
  padding: 7px 11px;
  font-size: 14px;
  line-height: 1.42857143;
  height: 36px\9;
  /* stylelint-enable */
  min-width: 200px;
  padding-right: 36px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
    height: 36px;
  }
}
textarea.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
  height: auto;
}
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input::placeholder {
  color: #8e8e93;
}
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:focus,
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:hover {
  border-color: #bd0003;
  outline: 0;
}
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input[disabled] {
  background-color: #f7f7fa;
  color: #c5c6c7;
  cursor: not-allowed;
}
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input[disabled]:hover {
  border-color: #e5e5ea;
}
.rs-picker-menu .rs-picker-search-bar::after {
  content: '\f002';
  font-family: 'rsuite-icon-font';
  font-style: normal;
  position: absolute;
  width: 14px;
  color: #c5c6c7;
  font-size: 14px;
  line-height: 1.42857143;
  top: 16px;
  right: 24px;
}
.rs-tree-menu.rs-picker-menu .rs-picker-search-bar::after,
.rs-check-tree-menu.rs-picker-menu .rs-picker-search-bar::after {
  top: 7px;
}
.rs-picker-menu .ReactVirtualized__Grid.ReactVirtualized__List:focus {
  outline: none;
}
.rs-picker-menu.rs-picker-inline {
  position: relative;
  box-shadow: none;
}
.rs-picker-default .rs-btn,
.rs-picker-input .rs-btn,
.rs-picker-default .rs-picker-toggle,
.rs-picker-input .rs-picker-toggle {
  background: #fff !important;
}
.rs-picker-default.rs-picker-disabled .rs-picker-toggle,
.rs-picker-input.rs-picker-disabled .rs-picker-toggle,
.rs-picker-default.rs-picker-disabled .rs-btn,
.rs-picker-input.rs-picker-disabled .rs-btn {
  background: #f7f7fa !important;
}
.rs-check-item:not(.rs-checkbox-disabled):hover,
.rs-check-item-focus {
  color: #575757;
  background-color: #fff2f4;
}
.rs-check-item .rs-checkbox-checker {
  padding: 0;
}
.rs-check-item .rs-checkbox-checker > label {
  position: relative;
  cursor: pointer;
  display: block;
  padding: 8px 12px 8px 44px;
  transition: color 0.3s linear, background-color 0.3s linear;
  line-height: 20px;
  /* stylelint-disable-next-line */
}
.ReactVirtualized__Grid .rs-check-item .rs-checkbox-checker > label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rs-checkbox-disabled.rs-check-item .rs-checkbox-checker > label {
  cursor: not-allowed;
}
.rs-check-item .rs-checkbox-checker > label .rs-checkbox-wrapper {
  left: 12px;
}
.grouped .rs-check-item .rs-checkbox-checker > label .rs-checkbox-wrapper,
.rs-picker-check-menu-group-children .rs-check-item .rs-checkbox-checker > label .rs-checkbox-wrapper {
  left: 26px;
}
.grouped .rs-check-item .rs-checkbox-checker > label,
.rs-picker-check-menu-group-children .rs-check-item .rs-checkbox-checker > label {
  padding-left: 58px;
}
.rs-popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1060;
  display: none;
  padding: 12px;
  font-size: 14px;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  opacity: 0;
  filter: drop-shadow(0 1px 8px rgba(0, 0, 0, 0.12));
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12)\9;
}
.rs-popover.fade {
  transition: opacity 0.1s linear, transform 0.1s ease-out;
}
.rs-popover.in {
  opacity: 1;
  transition: opacity 0.15s linear, transform 0.15s ease-in;
}
.rs-popover > .rs-popover-arrow {
  border-width: 6px;
}
.rs-popover > .rs-popover-arrow,
.rs-popover > .rs-popover-arrow::after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rs-popover > .rs-popover-arrow::after {
  border-width: 6px;
  content: '';
}
.rs-popover-title {
  margin: 0;
  font-size: 14px;
  line-height: 1.42857143;
}
.rs-popover-content {
  font-size: 12px;
  line-height: 1.66666667;
}
.rs-popover-title ~ .rs-popover-content {
  margin-top: 8px;
}
.rs-popover[class*='placement-top'] {
  margin-top: -8px;
  transform: translate(0, 0);
}
.rs-popover[class*='placement-top'].in {
  transform: translate(0, -2px);
}
.rs-popover[class*='placement-top'] > .rs-popover-arrow {
  margin-left: -6px;
  border-bottom-width: 0;
  bottom: -6px;
}
.rs-popover[class*='placement-top'] > .rs-popover-arrow::after {
  bottom: 1px;
  margin-left: -6px;
  border-bottom-width: 0;
  border-top-color: #fff;
}
/* rtl:begin:ignore */
.rs-popover[class*='placement-right'] {
  margin-left: 8px;
  transform: translate(0, 0);
}
.rs-popover[class*='placement-right'].in {
  transform: translate(2px, 0);
}
.rs-popover[class*='placement-right'] > .rs-popover-arrow {
  left: -6px;
  margin-top: -6px;
  border-left-width: 0;
}
.rs-popover[class*='placement-right'] > .rs-popover-arrow::after {
  left: 1px;
  bottom: -6px;
  border-left-width: 0;
  border-right-color: #fff;
}
.rs-popover[class*='placement-left'] {
  margin-left: -8px;
  transform: translate(0, 0);
}
.rs-popover[class*='placement-left'].in {
  transform: translate(-2px, 0);
}
.rs-popover[class*='placement-left'] > .rs-popover-arrow {
  right: -6px;
  margin-top: -6px;
  border-right-width: 0;
}
.rs-popover[class*='placement-left'] > .rs-popover-arrow::after {
  right: 1px;
  border-right-width: 0;
  border-left-color: #fff;
  bottom: -6px;
}
/* rtl:end:ignore */
.rs-popover[class*='placement-bottom'] {
  margin-top: 8px;
  transform: translate(0, 0);
}
.rs-popover[class*='placement-bottom'].in {
  transform: translate(0, 2px);
}
.rs-popover[class*='placement-bottom'] > .rs-popover-arrow {
  margin-left: -6px;
  border-top-width: 0;
  top: -6px;
}
.rs-popover[class*='placement-bottom'] > .rs-popover-arrow::after {
  top: 1px;
  margin-left: -6px;
  border-top-width: 0;
  border-bottom-color: #fff;
}
.rs-popover.placement-bottom > .rs-popover-arrow,
.rs-popover.placement-top > .rs-popover-arrow {
  left: 50%;
}
.rs-popover.placement-bottom-end > .rs-popover-arrow,
.rs-popover.placement-top-end > .rs-popover-arrow {
  right: 4px;
}
.rs-popover.placement-left > .rs-popover-arrow,
.rs-popover.placement-right > .rs-popover-arrow {
  top: 50%;
}
.rs-popover.placement-left-end > .rs-popover-arrow,
.rs-popover.placement-right-end > .rs-popover-arrow {
  bottom: 4px;
}
.rs-popover-full {
  padding: 0;
}
.rs-popover-full .rs-popover-content {
  margin-top: 0;
}
.rs-popover.placement-top-start.rs-popover-full > .rs-popover-arrow,
.rs-popover.placement-bottom-start.rs-popover-full > .rs-popover-arrow {
  margin-left: 6px;
}
.rs-popover.placement-right-start.rs-popover-full > .rs-popover-arrow,
.rs-popover.placement-left-start.rs-popover-full > .rs-popover-arrow {
  margin-top: 6px;
}
.rs-radio {
  position: relative;
  display: block;
}
.rs-radio-disabled label {
  cursor: not-allowed;
}
.rs-radio label {
  line-height: 1.57142857;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}
.rs-radio .rs-radio-inner::before {
  border-color: #00000073;
}
.rs-radio.rs-radio-disabled label {
  cursor: not-allowed;
}
.rs-radio-disabled > .rs-radio-checker > label {
  color: #c5c6c7;
}
.rs-radio-inline {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  margin-top: 0;
  margin-left: 10px;
}
.rs-radio-group-inline {
  display: inline-block;
  margin-left: -10px;
}
.rs-radio-group-picker {
  color: #575757;
  border: 1px solid #e5e5ea;
  border-radius: 4px;
  margin-left: 0;
}
.rs-radio-group-picker .rs-radio-inline {
  margin-left: 0;
}
.rs-radio-group-picker .rs-radio-checker {
  padding: 0;
  min-height: auto;
}
.rs-radio-group-picker .rs-radio-wrapper {
  display: none;
}
.rs-radio-group-picker .rs-radio-checker > label {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  outline: 0 !important;
  white-space: nowrap;
  border: none;
  user-select: none;
  /* stylelint-disable */
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  /* stylelint-enable */
  color: #8e8e93;
  transition: color 0.3s linear;
  padding: 7px 10px;
}
.rs-radio-group-picker .rs-radio-checker > label.rs-btn-ghost {
  padding-top: 7px;
  padding-bottom: 7px;
}
.rs-radio-group-picker .rs-radio-checker > label.rs-btn-icon {
  padding-left: 24px;
  height: 36px;
  position: relative;
}
.rs-btn-ghost.rs-radio-group-picker .rs-radio-checker > label.rs-btn-icon {
  padding-left: 22px;
}
.rs-radio-group-picker .rs-radio-checker > label.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left {
  padding-left: 44px;
}
.rs-radio-group-picker .rs-radio-checker > label.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right {
  padding-left: 12px;
  padding-right: 44px;
}
.rs-radio-group-picker .rs-radio-checker > label.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon {
  left: auto;
  right: 0;
}
.rs-radio-group-picker .rs-radio-checker > label.rs-btn-icon > .rs-icon {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 36px;
  height: 36px;
  padding: 8px 0;
  font-size: 14px;
  line-height: 1.42857143;
}
.rs-btn-ghost.rs-radio-group-picker .rs-radio-checker > label.rs-btn-icon > .rs-icon {
  width: 34px;
  height: 34px;
  padding: 7px 0;
}
.rs-radio-group-picker .rs-radio-checker > label.rs-btn-icon > .rs-icon > svg {
  height: 14px;
  vertical-align: middle;
}
.rs-radio-group-picker .rs-radio-checker > label.rs-btn-icon-circle {
  width: 36px;
  padding: 8px;
}
.rs-radio-group-picker .rs-radio-checker > label:hover,
.rs-radio-group-picker .rs-radio-checker > label:focus {
  color: #191919;
  text-decoration: none;
}
.rs-radio-group-picker .rs-radio-checker > label:active,
.rs-radio-group-picker .rs-radio-checker > label.rs-btn-active {
  outline: 0;
  background-image: none;
}
.rs-radio-group-picker .rs-radio-checker > label:hover,
.rs-radio-group-picker .rs-radio-checker > label:active {
  color: #575757;
}
.rs-radio-group-picker .rs-radio-checked .rs-radio-checker > label {
  color: #000000E6;
}
.rs-radio-group-picker .rs-radio-disabled .rs-radio-checker > label {
  opacity: 0.3;
  cursor: not-allowed;
}
.rs-radio-group-picker .rs-radio-disabled:not(.rs-radio-checked) .rs-radio-checker > label {
  color: #8e8e93;
}
.rs-radio-checker {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 42px;
  min-height: 42px;
  line-height: 1;
  position: relative;
}
.rs-radio-wrapper {
  position: absolute;
  width: 22px;
  height: 22px;
  display: inline-block;
  left: 10px;
  top: 10px;
}
.rs-radio-wrapper [type='radio'] {
  opacity: 0;
}
.rs-radio-wrapper::before,
.rs-radio-wrapper::after,
.rs-radio-wrapper .rs-radio-inner::before,
.rs-radio-wrapper .rs-radio-inner::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  display: block;
}
.rs-radio-wrapper::before,
.rs-radio-wrapper .rs-radio-inner::before,
.rs-radio-wrapper .rs-radio-inner::after {
  width: 22px;
  height: 22px;
}
.rs-radio-wrapper::before {
  border: 1px solid #00000073;
  background-color: transparent;
  border-radius: 50%;
  transform: scale(1);
  opacity: 0.7;
  visibility: hidden;
  transition: transform 0.2s linear, opacity 0.2s linear;
}
.rs-radio-checked .rs-radio-wrapper::before {
  transform: scale(1.5);
  opacity: 0;
  visibility: visible;
}
.rs-radio-wrapper::after {
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
}
.rs-radio-wrapper .rs-radio-inner::before {
  border: 1px solid #00000073;
  background-color: transparent;
  border-radius: 50%;
  transition: background 0.2s linear, border 0.2s linear, box-shadow 0.2s linear;
}
.rs-radio-checker:hover .rs-radio-wrapper .rs-radio-inner::before {
  border-color: #00000073;
}
.rs-radio-checked .rs-radio-wrapper .rs-radio-inner::before {
  border-color: #00000073;
  background-color: #00000073;
}
.rs-radio-disabled:not(.rs-radio-checked) .rs-radio-wrapper .rs-radio-inner::before {
  border-color: #f7f7fa;
  background-color: #f7f7fa;
}
.rs-radio-disabled.rs-radio-checked .rs-radio-wrapper .rs-radio-inner::before {
  opacity: 0.3;
}
.rs-radio-wrapper .rs-radio-inner::after {
  width: 6px;
  height: 6px;
  background: #000000;
  margin-top: 8px;
  margin-left: 8px;
  border-radius: 50%;
  opacity: 0;
  transform: scale(0);
  transition: opacity 0.2s ease-in, transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}
.rs-radio-checked .rs-radio-wrapper .rs-radio-inner::after {
  transform: scale(1);
  opacity: 1;
}
.rs-radio-wrapper [type='radio']:focus ~ .rs-radio-inner::before {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.3);
}
:hover.rs-radio-wrapper [type='radio']:focus ~ .rs-radio-inner::before {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.3);
}
.rs-radio-checked .rs-radio-wrapper [type='radio']:focus ~ .rs-radio-inner::before {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.3);
}
.rs-picker-subtle .picker-subtle-toggle {
  position: relative;
  z-index: 5;
  padding-right: 32px;
  display: inline-block;
  outline: none;
  cursor: pointer;
}
.rs-picker-subtle .picker-subtle-toggle-custom-title {
  padding: 0 !important;
}
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle {
  cursor: not-allowed;
}
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:hover,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:focus,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:active {
  background: none;
}
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:hover::after,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:focus::after,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:active::after {
  display: none;
}
.rs-picker-select-menu-items {
  margin-bottom: 4px;
  overflow-y: auto;
}
.rs-picker-select-menu-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.rs-picker-select-menu-items .rs-picker-select-menu-group:not(:first-child) {
  margin-top: 6px;
  border-top: 1px solid #e5e5ea;
  padding-top: 6px;
}
.rs-picker-select-menu-items .rs-picker-select-menu-group-title {
  padding: 8px 12px;
  padding-right: 32px;
  position: relative;
  cursor: default;
  transition: color 0.3s linear, background-color 0.3s linear;
  color: #272c36;
}
.rs-picker-select-menu-items .rs-picker-select-menu-group-title:hover {
  background-color: #fff2f4;
}
.rs-picker-select-menu-items .rs-picker-select-menu-group-title .rs-picker-select-menu-group-caret {
  display: inline-block;
  margin-left: 2px;
  position: absolute;
  top: 8px;
  right: 12px;
  transition: transform 0.3s linear;
}
.rs-picker-select-menu-items .rs-picker-select-menu-group-title .rs-picker-select-menu-group-caret::before {
  content: '\F0D7';
  font-family: 'rsuite-icon-font';
}
.rs-picker-select-menu-items .rs-picker-select-menu-group.folded .rs-picker-select-menu-group-caret {
  transform: rotate(90deg);
}
.rs-picker-select-menu-item {
  display: block;
  padding: 8px 12px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #575757;
  cursor: pointer;
  transition: color 0.3s linear, background-color 0.3s linear;
  text-decoration: none;
  width: 100%;
  /* stylelint-disable-next-line */
}
.ReactVirtualized__Grid .rs-picker-select-menu-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rs-picker-select-menu-item:not(.rs-picker-select-menu-item-disabled):hover,
.rs-picker-select-menu-item.rs-picker-select-menu-item-focus {
  color: #575757;
  background-color: #fff2f4;
  text-decoration: none;
}
.rs-picker-select-menu-item.rs-picker-select-menu-item-active {
  font-weight: bold;
}
.rs-picker-select-menu-item.rs-picker-select-menu-item-active,
.rs-picker-select-menu-item.rs-picker-select-menu-item-active:hover {
  color: #bd0003;
  background-color: #fff2f4;
}
.rs-picker-select-menu-item.rs-picker-select-menu-item-disabled {
  color: #c5c6c7;
  cursor: not-allowed;
  text-decoration: none;
}
.rs-picker-select-menu-item.rs-picker-select-menu-item-active.rs-picker-select-menu-item-disabled,
.rs-picker-select-menu-item.rs-picker-select-menu-item-active.rs-picker-select-menu-item-disabled:hover {
  color: #ed8c96;
}
.grouped .rs-picker-select-menu-item,
.rs-picker-select-menu-group-children .rs-picker-select-menu-item {
  padding-left: 26px;
}
.rs-btn-toggle {
  height: 24px;
  min-width: 44px;
  border-radius: 12px;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  vertical-align: middle;
  background-color: #d9d9d9;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, width 0.2s ease-in-out;
  user-select: none;
}
.rs-btn-toggle::after {
  width: 18px;
  height: 18px;
  left: 3px;
  top: 3px;
  border-radius: 9px;
}
.rs-btn-toggle:active::after {
  width: 21.6px;
}
.rs-btn-toggle .rs-btn-toggle-inner {
  margin-left: 24px;
  margin-right: 8px;
  height: 24px;
  line-height: 2;
  font-size: 12px;
}
.rs-btn-toggle .rs-btn-toggle-inner .rs-icon {
  font-size: 12px;
}
.rs-btn-toggle.rs-btn-toggle-checked::after {
  left: 100%;
  margin-left: -21px;
}
.rs-btn-toggle.rs-btn-toggle-checked:active::after {
  margin-left: -24.6px;
}
.rs-btn-toggle.rs-btn-toggle-checked .rs-btn-toggle-inner {
  margin-right: 24px;
  margin-left: 8px;
}
.rs-btn-toggle:hover {
  background-color: #c5c6c7;
}
.rs-btn-toggle::after {
  content: '';
  cursor: pointer;
  position: absolute;
  background-color: #fff;
  transition: left 0.2s ease-in-out, margin-left 0.2s ease-in-out, width 0.2s ease-in-out;
}
.rs-btn-toggle-inner {
  color: #ffffff;
  display: block;
  transition: margin 0.2s ease-in-out;
}
.rs-btn-toggle-checked {
  background-color: #D40511;
}
.rs-btn-toggle-checked:hover {
  background-color: #cc0209;
}
.rs-btn-toggle-disabled,
.rs-btn-toggle-disabled::after {
  cursor: not-allowed;
}
.rs-btn-toggle-disabled,
.rs-btn-toggle-disabled:hover {
  background-color: #f7f7fa;
}
.rs-btn-toggle-disabled.rs-btn-toggle-checked,
.rs-btn-toggle-disabled.rs-btn-toggle-checked:hover {
  background-color: #f7c1c7;
}
.rs-btn-toggle-sm {
  height: 14px;
  min-width: 26px;
  border-radius: 7px;
}
.rs-btn-toggle-sm::after {
  width: 10px;
  height: 10px;
  left: 2px;
  top: 2px;
  border-radius: 5px;
}
.rs-btn-toggle-sm:active::after {
  width: 12px;
}
.rs-btn-toggle-sm .rs-btn-toggle-inner {
  margin-left: 14px;
  margin-right: 6px;
  height: 14px;
  line-height: 1.4;
  font-size: 10px;
}
.rs-btn-toggle-sm .rs-btn-toggle-inner .rs-icon {
  font-size: 10px;
}
.rs-btn-toggle-sm.rs-btn-toggle-checked::after {
  left: 100%;
  margin-left: -12px;
}
.rs-btn-toggle-sm.rs-btn-toggle-checked:active::after {
  margin-left: -14px;
}
.rs-btn-toggle-sm.rs-btn-toggle-checked .rs-btn-toggle-inner {
  margin-right: 14px;
  margin-left: 6px;
}
.rs-btn-toggle-md {
  height: 24px;
  min-width: 44px;
  border-radius: 12px;
}
.rs-btn-toggle-md::after {
  width: 18px;
  height: 18px;
  left: 3px;
  top: 3px;
  border-radius: 9px;
}
.rs-btn-toggle-md:active::after {
  width: 21.6px;
}
.rs-btn-toggle-md .rs-btn-toggle-inner {
  margin-left: 24px;
  margin-right: 8px;
  height: 24px;
  line-height: 2;
  font-size: 12px;
}
.rs-btn-toggle-md .rs-btn-toggle-inner .rs-icon {
  font-size: 12px;
}
.rs-btn-toggle-md.rs-btn-toggle-checked::after {
  left: 100%;
  margin-left: -21px;
}
.rs-btn-toggle-md.rs-btn-toggle-checked:active::after {
  margin-left: -24.6px;
}
.rs-btn-toggle-md.rs-btn-toggle-checked .rs-btn-toggle-inner {
  margin-right: 24px;
  margin-left: 8px;
}
.rs-btn-toggle-lg {
  height: 30px;
  min-width: 55px;
  border-radius: 15px;
}
.rs-btn-toggle-lg::after {
  width: 22px;
  height: 22px;
  left: 4px;
  top: 4px;
  border-radius: 11px;
}
.rs-btn-toggle-lg:active::after {
  width: 26.4px;
}
.rs-btn-toggle-lg .rs-btn-toggle-inner {
  margin-left: 30px;
  margin-right: 10px;
  height: 30px;
  line-height: 2.14285714;
  font-size: 14px;
}
.rs-btn-toggle-lg .rs-btn-toggle-inner .rs-icon {
  font-size: 14px;
}
.rs-btn-toggle-lg.rs-btn-toggle-checked::after {
  left: 100%;
  margin-left: -26px;
}
.rs-btn-toggle-lg.rs-btn-toggle-checked:active::after {
  margin-left: -30.4px;
}
.rs-btn-toggle-lg.rs-btn-toggle-checked .rs-btn-toggle-inner {
  margin-right: 30px;
  margin-left: 10px;
}
