.label {
  background-color: white;
  width: auto;
  padding: .1em .2em;
  display: inline-flex;
  border: 1px solid;
  white-space: nowrap;
}

.labelReversed {
  @extend .label;

  float: right;
}

.labelHeading {
  font-weight: 900;
  max-width: 245px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.labelWrapper,
.label,
.area,
.group {
  pointer-events: none;
}