@import "./_variables.scss";

.routeButton {
  padding: 1.5em 1em;
  border-radius: $border-radius;
  border: 3px solid transparent;
  display: inline-block;
  background-color: white;
  box-shadow: 0px 1px 2px #00000029;
  cursor: pointer;
  text-align: center;
  width: calc(50% - 1em);
  height: 100%;
  min-width: 180px;
  max-height: 7em;
  margin: auto 0.5em;
  font: inherit;
  font-weight: 600;

  img {
    max-height: 2em;
    display: block;
    margin: 0 auto .5em;
    filter: grayscale(1);
  }

  &:hover {
    @extend .active;
  }

  &:focus {
    outline: none;
  }

  @media screen and (max-width: $mobile-breakpoint) {
    margin-left: 0;
    margin-right: 1em;
    width: calc(50% - .5em);
    padding: 1.5em .75em;

    &:nth-child(2) {
      margin-right: 0;
    }
  }
  
  @media screen and (max-width: $small-mobile-breakpoint) {
    min-width: unset;
    font-size: .9em;
  }
}

.wrapper {
  display: flex;

  @media screen and (max-width: $mobile-breakpoint) {
    width: 100%;
    max-width: 500px;
  }
}

.active {
    color: $DHL-red;
    border-color: $DHL-red;
    transition: 0.75s ease-out;
    transition-property: border, color;

    img {
      filter: grayscale(0);
    }
}