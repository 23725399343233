.wrapper {
  background: rgba(white, .95);
  position: absolute;
  top: 0;
  width: 100%;
  padding: 2em;
  height: calc(100% + 2em);
  z-index: 5;
  overflow: scroll;
}

.heading {
  font-size: 2em;
  display: block;
  font-weight: 600;
  color: black;
  margin-bottom: 2em;
}

.subHeading {
  font-weight: 600;
  font-size: 1.1em;
  margin-bottom: .7em;
  display: block;
  color: black;
}

.glossaryItem {
  margin: 2em 0;

  p {
    max-width: 700px;
  }
}

.closeButton {
  position: absolute;
  top: 2em;
  right: 2em;
  background-color: transparent;
  width: 3em;
  height: 3em;

  img {
    width: 100%;
  }

  &:focus {
    outline: none;
  }
}