[class*="GCIClient_wrapper"] {
  @font-face {
    font-family: Delivery;
    src: url("../assets/fonts/delivery_rg-webfont.woff2") format("woff2"),
      url("../assets/fonts/delivery_rg-webfont.woff") format("woff");
  }

  @font-face {
    font-family: Delivery;
    src: url("../assets/fonts/delivery_cdblk-webfont.woff2") format("woff2"),
      url("../assets/fonts/delivery_cdblk-webfont.woff") format("woff");
    font-weight: 900;
  }

  @font-face {
    font-family: Delivery;
    src: url("../assets/fonts/delivery_cdlt-webfont.woff2") format("woff2"),
      url("../assets/fonts/delivery_cdlt-webfont.woff") format("woff");
    font-weight: 200;
  }
}
