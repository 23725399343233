@import "./_variables.scss";

.header {
  background: $DHL-yellow;
  padding: 2em;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media screen and (max-width: $tablet-breakpoint) {
    align-items: flex-start;
  }

  @media screen and (max-width: $mobile-breakpoint) {
    flex-direction: column;
    padding: 1.5em;
  }
}

.firstFilterWrapper {
  > * {
    margin: 0 0.5em;
  }

  .row,
  .col {
    display: inline-block;
  }

  .hasHyphen {
    position: relative;
    margin-right: 1.5em;

    &:after {
      content: "-";
      position: absolute;
      right: -0.9em;
      height: 0;
      bottom: 0;
      height: 42px;
      display: flex;
      align-items: center;
    }
  }

  @media screen and (max-width: $tablet-breakpoint) {
    min-width: 300px;

    .row {
      display: flex;
      flex-direction: column;
      margin: 1em auto;
      width: 100%;
    }

    .stableRow {
      flex-direction: row;
      align-items: flex-end;
    }
  }

  @media screen and (max-width: $mobile-breakpoint) {
    width: 100%;
    max-width: 500px;

    .row {
      display: inline-block;
      width: calc(50% - 1em);
      margin-right: 1em;
      margin-top: 1.5em;

      &:nth-child(2) {
        margin-right: 0;
      }
    }

    .stableRow {
      margin-left: 1em;
    }

    .col {
      width: calc(50% - 1em);
    }
  }

  @media screen and (max-width: $small-mobile-breakpoint) {
    display: flex;
    flex-direction: column;
    min-width: unset;

    .row {
      width: 100%;
    }

    .stableRow {
      margin-left: 0em;
    }

    .col {
      width: calc(50% - 0.75em);

      .calendarIcon {
        width: 100%;
      }
    }

    .hasHyphen {
      margin-right: 1.5em;
    }
  }
}

.filterWrapper {
  background: $light-grey;
  padding: 1em 2.5em;

  @media screen and (max-width: $small-mobile-breakpoint) {
    padding: 1em 1.5em;
  }
}

.filterItem {
  margin: 0.5em 0;
  display: inline-block;
  width: 33.33%;
  max-width: 500px;

  > span {
    margin-right: 0.5em;
  }

  [value="false"] ~ .subFilter {
    opacity: 0;
    max-height: 0;
    transition: 0.2s ease-out;
    transition-property: opacity max-height;
    display: block;
    pointer-events: none;
  }

  [value="true"] ~ .subFilter {
    opacity: 1;
    max-height: 2em;
    transition: 0.2s ease-out;
    transition-property: opacity max-height;
    pointer-events: all;
  }

  .subFilter label span:before {
    background-color: white;
  }

  @media screen and (max-width: $tablet-breakpoint) {
    width: 50%;

    &.toggleinformation {
      margin-left: 0;
    }
  }

  @media screen and (max-width: $mobile-breakpoint) {
    width: 100%;

    [value="true"] ~ .subFilter .rs-checkbox-inline {
      margin-left: 0.5em;
    }
  }
}

.togglecapital > [value="true"],
.togglecapital > [value="true"]:hover {
  background-color: #1e9e5e;
}

.togglepeople > [value="true"],
.togglepeople > [value="true"]:hover {
  background-color: #ce46ce;
}

.toggleinformation {
  margin-left: 33.33%;
}

.toggleinformation > [value="true"],
.toggleinformation > [value="true"]:hover {
  background-color: #4486f8;
}

.toggletrade > [value="true"],
.toggletrade > [value="true"]:hover {
  background-color: #333333;
}

.subHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  line-height: 1;

  @media screen and (max-width: $tablet-breakpoint) {
    align-items: center;
  }
}

.heading {
  font-size: 1.9em;
  font-weight: 900;
  padding-right: 2em;
  margin-left: 1em;
  margin-top: 1.4em;
  display: inline-block;
  color: black;

  @media screen and (max-width: $tablet-breakpoint) {
    margin-bottom: 1em;
  }

  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 1.5em;
    margin-bottom: 1.4em;
    margin-left: 21px;
  }
}

.subHeading {
  font-weight: 400;
  color: black;
  display: block;
  margin-bottom: 1em;
}

.explanation {
  margin-left: 2em;

  @media screen and (max-width: $mobile-breakpoint) {
    margin-top: 2em;
    display: inline-block;
    padding-right: 2em;
  }
}

.contentWrapper {
  position: relative;
}

.wrapper .graphWrapper {
  margin: 2em 2.5em 3em 4em;
  width: calc(100% - 6.5em);
  height: 500px;
  max-width: $max-width;
  padding-bottom: 2em;

  @media screen and (max-width: $mobile-breakpoint) {
    margin: 2em 2em 1em;
    width: calc(100% - 3em);
  }
}

.calendarIcon {
  > a {
    padding-right: calc(32px + 1.8em) !important;

    &:after {
      content: "";
      position: absolute;
      right: 2em;
      top: 13px;
      width: 15px;
      display: block;
      height: 15px;
      background-image: url("../assets/images/icon-calendar.svg");
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

.snapshotWrapper {
  background-color: white;
  border: 1px solid white;
}

.selectDisplayBy {
  @media screen and (max-width: $small-mobile-breakpoint) {
    width: 100%;
  }
}

.controls {
  display: flex;
  margin-right: 2em;

  > * {
    margin: unset;
    margin-bottom: 5px;
  }

  @media screen and (max-width: $mobile-breakpoint) {
    margin-right: 21px;
  }
}

.openGlossaryButton {
  background: none;
  border: none;
  position: relative;
  display: flex;
  margin-right: 6px;
  width: 16px;
  padding: 0;

  img {
    width: 100%;
    height: auto;
  }

  &:focus {
    outline: none;
  }

  &:before {
    opacity: 0;
    transition: 0.3s ease-out;
    transition-property: opacity;
    content: "";
    background-color: $DHL-red;
    height: 2px;
    width: 1em;
    position: absolute;
    bottom: -5px;
    right: 0;
  }

  &:hover {
    &:before {
      opacity: 1;
      transition: 0.2s ease-out;
      transition-property: opacity;
    }
  }
}

.source {
  width: calc(100% - 4em);
  margin: 1em 2em 2em 2em;

  @media screen and (max-width: $tablet-breakpoint) {
    margin: 2em 2em 2em 2em;
  }

  span {
    font-weight: 900;
  }
}
