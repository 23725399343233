@import "./_variables.scss";

.header {
  background: $DHL-yellow;
  padding: 2em;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media screen and (max-width: $mobile-breakpoint) {
    flex-direction: column;
    padding: 1.5em;
    align-items: center;
  }
}

.firstFilterWrapper {
  > * {
    margin: 0 0.5em;
  }

  @media screen and (max-width: $mobile-breakpoint) {
    width: 100%;
    max-width: 500px;

    .row {
      display: flex;
      justify-content: space-between;
      margin: 1em auto;
      width: 100%;
      flex-direction: column;
    }

    .col {
      width: calc(50% - 1em);
    }
  }
}

.filterWrapper {
  background: $light-grey;
  padding: 1em 2.5em;

  .radioFilterGroup {
    display: flex;
    justify-content: space-between;
    max-width: 700px;

    .radioFilter label span:before {
      background-color: white;
    }

    .radioFilter label {
      line-height: 22px;
    }
  }

  @media screen and (max-width: $mobile-breakpoint) {
    padding: 1em 1.5em;

    .radioFilterGroup {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
    }

    .radioFilterGroup  > div {
      display: inline-block;
      width: 50%;
    } 
  }
}

.heading {
  font-size: 1.9em;
  font-weight: 900;
  padding-right: 2em;
  line-height: 1.25;
  color: black;
  max-width: 60%;

  @media screen and (max-width: $tablet-breakpoint) {
    margin-bottom: 1em;
    max-width: unset;
  }

  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 1.5em;
  }
}

.subHeading {
  font-weight: 400;
  color: black;
  display: block;
  margin-bottom: 1em;
}

.showMore {
  padding: 12px;
  font: inherit;
  font-weight: 900;
  border-radius: $border-radius;
  margin: 0 1.5em 0 1.5em;
  display: inline-block;
  width: calc(50% - 3em);
  background-color: $DHL-red;
  color: white;
  transition: .3s ease-out;
  transition-property: background-color;
  border: none;

  &:active,
  &:focus {
    outline: none;
  }

  &:hover {
    background-color: #EB131E;
    transition: .2s ease-out;
    transition-property: background-color;
  }

  &[disabled] {
    background-color: rgba(0,0,0,.45);
    transition: .2s ease-out;
    transition-property: background-color;
  }

  @media screen and (max-width: $mobile-breakpoint) {
    margin: .5em;
    width: calc(50% - 1em);
  }
}

.changeLimitButtons {
  display: flex;
  align-items: center;
  border: 1px solid #e5e5ea;
  box-shadow: 0px 1px 2px #00000029;
  border-radius: $border-radius;
  height: 43px;
  
  button {
    border: none;
    background-color: transparent;
    padding: .3em .5em;
    font-size: 1.5em;
    font-weight: 400;
    color: #8e8e93;

    &:active, 
    &:focus {
      outline: none;
    }

    &[disabled] {
      opacity: .3;
    }
  }

  span {
    margin: auto .2em;
    display: inline-block;
    text-align: center;
    transform: translateY(.05em);
    color: black;
    min-width: 30px;
    font-size: .9em;
  }

  @media screen and (max-width: $mobile-breakpoint) {
    span {
      margin: auto 0;
    }
  }
}

.calendarIcon {
  > a {
    padding-right: calc(32px + 1.8em) !important;

    &:after {
      content: '';
      position: absolute;
      right: 2em;
      top: 13px;
      width: 15px;
      display: block;
      height: 15px;
      background-image: url('../assets/images/icon-calendar.svg');
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

.selectRegion {
  width: 200px;

  @media screen and (max-width: $small-mobile-breakpoint) {
    width: 100%;
  } 
}

.controls {
  display: flex;
  align-items: flex-end;

  @media screen and (max-width: $tablet-breakpoint) {
    .changeLimitButtons {
      margin-left: 0;
    }
  }
}

.snapshotWrapper {
  background-color: white;
  border: 1px solid white;
  padding-bottom: 25px;
}

.openGlossaryButton {
  background: none;
  border: none;
  position: relative;
  display: flex;

  &:focus {
    outline: none;
  }

  &:before {
    opacity: 0;
    transition: .3s ease-out;
    transition-property: opacity;
    content: '';
    background-color: $DHL-red;
    height: 2px;
    width: 1em;
    position: absolute;
    bottom: -.4em;
    right: .4em;
  }

  &:hover {
    &:before {
      opacity: 1;
      transition: .2s ease-out;
      transition-property: opacity;
    }
  }
}