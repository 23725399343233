@import "./_variables.scss";
@import "./_normalize.scss";

.wrapper {
  padding-bottom: 2em;
  margin: 2em auto;
  font-family: Delivery, sans-serif;
  font-size: 14px;
  max-width: 1100px;
  border-radius: $border-radius;
  overflow: hidden;
  box-shadow: 0 0 1em rgba(0,0,0, .1);
  position: relative;

  svg:not(:root) {
    overflow: visible;
  }

  @media screen and (min-width: $tablet-breakpoint) {
    font-size: 16px;
  }
}

.licenseTXT {
  font-size: 8px;
  position: absolute;
  right: 30px;
  bottom: 10px;
  color: rgba(0,0,0, .10);
  text-decoration: none;

  &:hover {
    color: rgba(0,0,0, .15);
  }

  &:focus,
  &:active {
    color: rgba(0,0,0, .10);
    text-decoration: none;
  }
}
