@import "./_variables.scss";

.downloadButton {
  position: relative;
  padding: .5em 0;
  background-color: transparent;

  &:after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    left: 50%;
    margin-left: -8px;
    background-image: url(../assets/images/icon-download.svg);
    background-size: contain;
    z-index: 5;
    pointer-events: none;
  }

  &:before {
    opacity: 0;
    transition: .3s ease-out;
    transition-property: opacity;
    content: '';
    background-color: $DHL-red;
    height: 2px;
    width: 15px;
    position: absolute;
    bottom: -5px;
    right: 14px;
  }

  &.rs-dropdown-open,
  &:hover,
  &:active {
    background-color: transparent;

    &:before {
      opacity: 1;
      transition: .2s ease-out;
      transition-property: opacity;
    }
  }

  a,
  a:hover,
  a span {
    background-color: transparent;
  }

  a span span {
    display: none !important;
  }

  a[tabindex="0"] {
    background: none;
  }
}

.wrapper {
  display: flex;
  justify-content: flex-end;
  max-width: $max-width;
  margin: 1.4em .5em;
  line-height: 1;
}