[class*="GCIClient_wrapper"] {
  .rs-picker-select .rs-btn-default {
    box-shadow: 0px 1px 2px #00000029;
  }

  .rs-picker-default .rs-picker-toggle.rs-btn {
    padding-top: 11px;
    padding-bottom: 10px;
  }

  .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
  .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
    top: 11px;
  }

  .rs-radio-checked .rs-radio-wrapper .rs-radio-inner:before,
  .rs-radio-wrapper .rs-radio-inner:before,
  .rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner:before,
  .rs-checkbox-wrapper .rs-checkbox-inner:before {
    background-color: white;
  }

  .rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::after,
  .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::after {
    margin-top: 5px;
    margin-left: 8px;
  }

  .rs-dropdown-menu {
    margin-top: 0.5em;
  }

  @media screen and (max-width: 767px) {
    .rs-checkbox-inline {
      margin-left: 0.5em;

      &:first-child {
        margin-left: 0.2em;
      }
    }
  }
}

.gciClient-menu-dropdown {
  font-family: Delivery, sans-serif;
  font-size: 0.9em;

  a {
    outline: none;
  }
}
